import { ParentType } from 'tillr-graphql';
import { IFormlyConfig } from './IFormlyConfig';

export default function getStaticTemplate(type: string): IFormlyConfig {
  switch (type) {
    case 'create-report': {
      return {
        fields: [
          {
            id: 'reportform-name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true,
            },
            key: 'name',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'reportform-sqlQuery',
            type: 'textarea',
            templateOptions: {
              label: 'SQL Query',
              required: true,
            },
            key: 'sqlQuery',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'reportform-uiData',
            type: 'textarea',
            templateOptions: {
              label: 'UiData',
              required: false,
            },
            key: 'uiData',
            className: 'col-xs-6',
          },
          {
            id: 'reportform-uiDataCopy',
            type: 'copy',
            defaultValue: `Format: <pre>{
  "pivot"?: {
    "keyColumn": string,
    "keyColumnFormat"?: string,
    "valueColumn": string
  },
  "views": [{
    "title": string,
    "type": "Bar" | "Pie" | "Sunburst" | "Table",
    "groupBy"?: string,
    "thenGroupBy"?: string
  }]
}</pre>`,
            key: 'uiDataCopy',
            className: 'col-xs-6',
          },
          {
            id: 'reportform-reportingData',
            type: 'tlrSelect',
            templateOptions: {
              label: 'Reporting database',
              required: true,
              options: [
                { name: 'System', value: 'SYSTEM' },
                { name: 'Tenant', value: 'TENANT' },
              ],
            },
            key: 'reportingDatabase',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'reportform-isPublished',
            type: 'checkbox',
            templateOptions: {
              label: 'Is Published?',
              required: true,
            },
            key: 'isPublished',
            className: 'col-xs-12 col-sm-12',
          },
        ],
      };
    }
    case 'create-task':
      return {
        fields: [
          {
            id: 'taskform-name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true,
            },
            key: 'name',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'taskform-description',
            type: 'textarea',
            templateOptions: {
              required: true,
              label: 'Description',
            },
            className: 'col-xs-12 col-sm-12',
            key: 'description',
          },
          {
            id: 'taskform-assignees',
            type: 'assignees',
            templateOptions: {
              label: 'Assignee(s)',
              required: true,
            },
            key: 'assignees',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'taskform-duedate',
            type: 'datepicker',
            data: {
              align: 'left',
            },
            templateOptions: {
              required: true,
              label: 'Due Date',
            },
            className: 'col-xs-6 clear',
            key: 'dueDate',
          },
          {
            id: 'taskform-attachments',
            type: 'attachments',
            templateOptions: {
              required: false,
              label: 'Attachments',
            },
            data: {
              parentTypeForAttachments: ParentType.Task,
            },
            className: 'col-xs-12 col-sm-12',
            key: 'attachments',
          },
        ],
      };
    case 'create-automated-task':
      return {
        fields: [
          {
            id: 'taskform-name',
            type: 'input',
            templateOptions: {
              label: 'Task name',
              required: true,
            },
            key: 'name',
            className: 'col-xs-12 col-sm-12',
            data: {
              help: 'Edit this value to give the task a more identifiable name',
            },
          },
          {
            id: 'taskform-description',
            type: 'textarea',
            templateOptions: {
              required: false,
              label: 'Additional comments',
            },
            className: 'col-xs-12 col-sm-12',
            key: 'description',
          },
          {
            id: 'taskform-assignees',
            type: 'assignees',
            templateOptions: {
              label: 'Assignee(s)',
              required: true,
            },
            key: 'assignees',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'taskform-duedate',
            type: 'duedate',
            data: {
              align: 'left',
            },
            templateOptions: {
              required: true,
              label: 'Due Date',
            },
            className: 'col-xs-6 clear',
            key: 'dueDate',
          },
        ],
      };
    case 'role':
      return {
        fields: [
          {
            id: 'role-name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true,
            },
            key: 'name',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'role-id',
            type: 'hidden',
            templateOptions: {
              label: 'ID',
              required: true,
            },
            key: 'id',
            className: 'col-xs-12 col-sm-12',
          },
        ],
      };
    case 'create-task-from-calendar':
      return {
        fields: [
          {
            id: 'taskform-name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true,
            },
            key: 'name',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'taskform-description',
            type: 'textarea',
            templateOptions: {
              required: true,
              label: 'Description',
            },
            className: 'col-xs-12 col-sm-12',
            key: 'description',
          },
          {
            id: 'taskform-assignees',
            type: 'assignees',
            templateOptions: {
              label: 'Assignee(s)',
              required: true,
            },
            key: 'assignees',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'taskform-duedate',
            type: 'input',
            data: {
              align: 'left',
            },
            templateOptions: {
              label: 'Due Date',
              disabled: true,
            },
            className: 'col-xs-6 clear',
            key: 'dueDateFriendly',
          },
          {
            id: 'taskform-attachments',
            type: 'attachments',
            templateOptions: {
              required: false,
              label: 'Attachments',
            },
            data: {
              parentTypeForAttachments: ParentType.Task,
            },
            className: 'col-xs-12 col-sm-12',
            key: 'attachments',
          },
        ],
      };
    case 'task':
      return {
        fields: [
          {
            id: 'taskform-status',
            type: 'tlrSelect',
            templateOptions: {
              required: true,
              options: [
                {
                  name: 'Open',
                  value: true,
                },
                {
                  name: 'Closed',
                  value: false,
                },
              ],
              label: 'Status:',
            },
            className: 'col-xs-6 clear',
            key: 'open',
            defaultValue: 'Open',
          },
          {
            id: 'taskform-completed',
            type: 'datepicker',
            data: {
              align: 'right',
            },
            templateOptions: {
              label: 'Completed Date:',
              required: true,
            },
            className: 'col-xs-6 pull-right',
            key: 'completedDate',
            hideExpression: "model['open'] !== false",
          },
          {
            id: 'taskform-name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true,
            },
            key: 'name',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'taskform-description',
            type: 'textarea',
            templateOptions: {
              required: true,
              label: 'Description',
            },
            className: 'col-xs-12 col-sm-12',
            key: 'description',
          },
          {
            id: 'taskform-assignees',
            type: 'assignees',
            templateOptions: {
              label: 'Assignee(s)',
              required: true,
            },
            key: 'assignees',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'taskform-duedate',
            type: 'datepicker',
            data: {
              align: 'left',
            },
            templateOptions: {
              required: true,
              label: 'Due Date',
            },
            className: 'col-xs-6 clear',
            key: 'dueDate',
          },
          {
            id: 'taskform-attachments',
            type: 'attachments',
            templateOptions: {
              required: false,
              label: 'Attachments',
            },
            data: {
              parentTypeForAttachments: ParentType.Task,
            },
            className: 'col-xs-12 col-sm-12',
            key: 'attachments',
          },
        ],
      };
    case 'quick-task':
      return {
        fields: [
          {
            id: 'taskform-name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true,
            },
            key: 'name',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'taskform-description',
            type: 'textarea',
            templateOptions: {
              required: true,
              label: 'Description',
            },
            className: 'col-xs-12 col-sm-12',
            key: 'description',
          },
          {
            id: 'taskform-assignees',
            type: 'assignees',
            templateOptions: {
              label: 'Assignee(s)',
              required: true,
            },
            key: 'assignedToUsers',
            className: 'col-xs-12 col-sm-12',
            hideExpression: 'true',
          },
          {
            id: 'taskform-duedate',
            type: 'datepicker',
            data: {
              align: 'left',
            },
            templateOptions: {
              required: true,
              label: 'Due Date',
            },
            className: 'col-xs-6 clear',
            key: 'dueDate',
          },
          {
            id: 'taskform-attachments',
            type: 'attachments',
            templateOptions: {
              required: false,
              label: 'Attachments',
            },
            data: {
              parentTypeForAttachments: ParentType.Task,
            },
            className: 'col-xs-12 col-sm-12',
            key: 'attachments',
          },
        ],
      };
    case 'task-quick-view':
      return {
        fields: [
          {
            id: 'taskform-status',
            type: 'tlrSelect',
            templateOptions: {
              required: true,
              options: [
                {
                  name: 'Open',
                  value: true,
                },
                {
                  name: 'Closed',
                  value: false,
                },
              ],
              label: 'Status:',
            },
            className: 'col-xs-6 clear',
            key: 'open',
            defaultValue: 'Open',
            hideExpression: 'true',
          },
          {
            id: 'taskform-completed',
            type: 'datepicker',
            data: {
              align: 'right',
            },
            templateOptions: {
              label: 'Completed Date:',
              required: true,
            },
            className: 'col-xs-6 pull-right',
            key: 'completedDate',
            hideExpression: 'true',
          },
          {
            id: 'taskform-name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true,
            },
            key: 'name',
            className: 'col-xs-12 col-sm-12',
            hideExpression: 'true',
          },
          {
            id: 'taskform-description',
            type: 'textarea',
            templateOptions: {
              required: true,
              label: 'Description',
            },
            className: 'col-xs-12 col-sm-12',
            key: 'description',
          },
          {
            id: 'taskform-assignees',
            type: 'assignees',
            templateOptions: {
              label: 'Assignee(s)',
              required: true,
            },
            key: 'assignees',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'taskform-duedate',
            type: 'datepicker',
            data: {
              align: 'left',
            },
            templateOptions: {
              required: true,
              label: 'Due Date',
            },
            className: 'col-xs-6 clear',
            key: 'dueDate',
            hideExpression: 'true',
          },
        ],
      };
    case 'task-quick-status':
      return {
        settings: {
          manage: {
            save: 'Save',
          },
        },
        fields: [
          {
            id: 'taskform-status',
            type: 'tlrSelect',
            templateOptions: {
              required: true,
              options: [
                {
                  name: 'Open',
                  value: true,
                },
                {
                  name: 'Closed',
                  value: false,
                },
              ],
              label: 'Status:',
            },
            className: 'col-xs-12 clear',
            key: 'open',
            defaultValue: true,
          },
          {
            id: 'taskform-completed',
            type: 'datepicker',
            data: {
              align: 'right',
            },
            templateOptions: {
              label: 'Completed Date:',
              required: true,
            },
            className: 'col-xs-12 clear',
            key: 'completedDate',
            hideExpression: "model['open'] !== false",
          },
        ],
      };
    case 'recurrence':
      return {
        fields: [
          {
            fieldGroup: [
              {
                type: 'datepicker',
                data: {
                  format: 'element',
                },
                templateOptions: {
                  required: false,
                  disabled: false,
                  options: [],
                  valueProp: 'name',
                  label: 'Start',
                },
                className: 'col-xs-12 col-sm-12',
                key: '884f6dab-4539-4767-b670-538e20302f4e',
              },
              {
                type: 'hr',
                data: {
                  format: 'element',
                },
                templateOptions: {
                  required: false,
                  disabled: false,
                  options: [],
                  valueProp: 'name',
                  label: 'HR1',
                },
                className: 'col-xs-12 col-sm-12',
                key: '2e5734ce-ead7-4591-a04e-be562317453b',
              },
              {
                type: 'tlrSelect',
                data: {
                  format: 'element',
                  prompt: 'Select',
                },
                templateOptions: {
                  required: false,
                  disabled: false,
                  options: [
                    {
                      name: 'Yearly',
                    },
                    {
                      name: 'Monthly',
                    },
                    {
                      name: 'Weekly',
                    },
                    {
                      name: 'Daily',
                    },
                  ],
                  valueProp: 'name',
                  label: 'Repeat',
                },
                className: 'col-xs-12 col-sm-12',
                key: '013191dc-7d44-4443-8a5d-2de4fb6f199c',
                defaultValue: 'Yearly',
              },
              {
                fieldGroup: [
                  {
                    type: 'radio',
                    data: {
                      format: 'element',
                    },
                    templateOptions: {
                      required: false,
                      disabled: false,
                      options: [
                        {
                          name: 'On',
                          value: '1',
                        },
                        {
                          name: 'On the',
                          value: '2',
                        },
                      ],
                      valueProp: 'name',
                      label: 'Yearly type',
                    },
                    className: 'col-xs-12 col-sm-12',
                    key: '6c3f8873-5b57-4c94-8312-0304499c5187',
                    defaultValue: '1',
                  },
                  {
                    fieldGroup: [
                      {
                        type: 'tlrSelect',
                        data: {
                          format: 'element',
                          prompt: 'Select',
                        },
                        templateOptions: {
                          required: false,
                          disabled: false,
                          options: [
                            {
                              name: 'January',
                            },
                            {
                              name: 'February',
                            },
                          ],
                          valueProp: 'name',
                          label: 'Yearly - On - Month',
                        },
                        className: 'col-xs-12 col-sm-12',
                        key: 'fb56c417-d993-416e-813c-7fb1c4f9d658',
                        defaultValue: 'January',
                      },
                      {
                        type: 'tlrSelect',
                        data: {
                          format: 'element',
                          prompt: 'Select',
                        },
                        templateOptions: {
                          required: false,
                          disabled: false,
                          options: [
                            {
                              name: '1',
                            },
                            {
                              name: '2',
                            },
                            {
                              name: '3',
                            },
                          ],
                          valueProp: 'name',
                          label: 'Yearly - On - Day',
                        },
                        className: 'col-xs-12 col-sm-12',
                        key: '85c5dca4-775c-4bbe-8e41-75f6c7002909',
                        defaultValue: '1',
                      },
                    ],
                    data: {
                      format: 'group',
                      label: 'Yearly - On',
                      key: 'f4a39672-3187-48b5-b289-2940cb4871e7',
                      rule: {
                        key: '6c3f8873-5b57-4c94-8312-0304499c5187',
                        value: '1',
                        action: 'show',
                      },
                    },
                    templateOptions: {
                      valueProp: 'name',
                    },
                    className: 'row',
                    wrapper: ['field-group'],
                    hideExpression:
                      "model['6c3f8873-5b57-4c94-8312-0304499c5187'].toString() !== '1'",
                  },
                  {
                    fieldGroup: [
                      {
                        type: 'tlrSelect',
                        data: {
                          format: 'element',
                          prompt: 'Select',
                        },
                        templateOptions: {
                          required: false,
                          disabled: false,
                          options: [
                            {
                              name: 'First',
                            },
                            {
                              name: 'Second',
                            },
                            {
                              name: 'Third',
                            },
                            {
                              name: 'Fourth',
                            },
                            {
                              name: 'Last',
                            },
                          ],
                          valueProp: 'name',
                          label: 'Yearly - On the - Type',
                        },
                        className: 'col-xs-12 col-sm-12',
                        key: 'ce142ec9-e2e7-4d91-a0ce-88ae609f89a5',
                        defaultValue: 'First',
                      },
                      {
                        type: 'tlrSelect',
                        data: {
                          format: 'element',
                          prompt: 'Select',
                        },
                        templateOptions: {
                          required: false,
                          disabled: false,
                          options: [
                            {
                              name: 'Monday',
                            },
                            {
                              name: 'Tuesday',
                            },
                            {
                              name: 'Wednesday',
                            },
                          ],
                          valueProp: 'name',
                          label: 'Yearly - On the - Day',
                        },
                        className: 'col-xs-12 col-sm-12',
                        key: 'b71761be-5338-4082-b70f-d839a7042b48',
                        defaultValue: 'Monday',
                      },
                      {
                        type: 'tlrSelect',
                        data: {
                          format: 'element',
                          prompt: 'Select',
                        },
                        templateOptions: {
                          required: false,
                          disabled: false,
                          options: [
                            {
                              name: 'January',
                            },
                            {
                              name: 'February',
                            },
                          ],
                          valueProp: 'name',
                          label: 'Yearly - On the  - Month',
                        },
                        className: 'col-xs-12 col-sm-12',
                        key: 'bd9b3d79-eb87-4057-9aa1-2a80b1179f33',
                        defaultValue: 'January',
                      },
                    ],
                    data: {
                      format: 'group',
                      label: 'Yearly - On the',
                      key: 'f4a39672-3187-48b5-b289-2940cb4871e7',
                      rule: {
                        key: '6c3f8873-5b57-4c94-8312-0304499c5187',
                        value: '2',
                        action: 'show',
                      },
                    },
                    templateOptions: {
                      valueProp: 'name',
                    },
                    className: 'row',
                    wrapper: ['field-group'],
                    hideExpression:
                      "model['6c3f8873-5b57-4c94-8312-0304499c5187'].toString() !== '2'",
                  },
                ],
                data: {
                  format: 'group',
                  label: 'Yearly Group',
                  key: 'e109967c-8eb2-4281-8098-b034cb5f49b3',
                  rule: {
                    key: '013191dc-7d44-4443-8a5d-2de4fb6f199c',
                    value: 'Yearly',
                    action: 'show',
                  },
                },
                templateOptions: {
                  valueProp: 'name',
                },
                className: 'row',
                wrapper: ['field-group'],
                hideExpression:
                  "model['013191dc-7d44-4443-8a5d-2de4fb6f199c'].toString() !== 'Yearly'",
              },
              {
                fieldGroup: [
                  {
                    type: 'input',
                    data: {
                      format: 'element',
                    },
                    templateOptions: {
                      required: false,
                      disabled: false,
                      options: [],
                      valueProp: 'name',
                      label: 'Every x month(s)',
                      placeholder: '',
                    },
                    className: 'col-xs-12 col-sm-12',
                    key: '82aca0d5-ce34-467a-8801-bdcce00edfe9',
                    defaultValue: '1',
                  },
                  {
                    type: 'radio',
                    data: {
                      format: 'element',
                    },
                    templateOptions: {
                      required: false,
                      disabled: false,
                      options: [
                        {
                          name: 'On day',
                          value: '1',
                        },
                        {
                          name: 'On the',
                          value: '2',
                        },
                      ],
                      valueProp: 'name',
                      label: 'Monthly type',
                    },
                    className: 'col-xs-12 col-sm-12',
                    key: 'f11c5488-b398-43bd-b4c9-756efad764ee',
                    defaultValue: '1',
                  },
                  {
                    fieldGroup: [
                      {
                        type: 'tlrSelect',
                        data: {
                          format: 'element',
                          prompt: 'Select',
                        },
                        templateOptions: {
                          required: false,
                          disabled: false,
                          options: [
                            {
                              name: '1',
                            },
                            {
                              name: '2',
                            },
                            {
                              name: '3',
                            },
                          ],
                          valueProp: 'name',
                          label: 'Monthly - On day - Day',
                        },
                        className: 'col-xs-12 col-sm-12',
                        key: '95ef4675-9582-4fe4-8756-b742660587fe',
                        defaultValue: '1',
                      },
                    ],
                    data: {
                      format: 'group',
                      label: 'Monthly - On day',
                      key: '496afa9a-ef98-438d-8222-df81b515b368',
                      rule: {
                        key: 'f11c5488-b398-43bd-b4c9-756efad764ee',
                        value: '1',
                        action: 'show',
                      },
                    },
                    templateOptions: {
                      valueProp: 'name',
                    },
                    className: 'row',
                    wrapper: ['field-group'],
                    hideExpression:
                      "model['f11c5488-b398-43bd-b4c9-756efad764ee'].toString() !== '1'",
                  },
                  {
                    fieldGroup: [
                      {
                        type: 'tlrSelect',
                        data: {
                          format: 'element',
                          prompt: 'Select',
                        },
                        templateOptions: {
                          required: false,
                          disabled: false,
                          options: [
                            {
                              name: 'First',
                            },
                            {
                              name: 'Second',
                            },
                            {
                              name: 'Third',
                            },
                            {
                              name: 'Fourth',
                            },
                            {
                              name: 'Last',
                            },
                          ],
                          valueProp: 'name',
                          label: 'Monthly - On the - Type',
                        },
                        className: 'col-xs-12 col-sm-12',
                        key: '60ac9f80-765f-46c7-827a-236296f84959',
                        defaultValue: 'First',
                      },
                      {
                        type: 'tlrSelect',
                        data: {
                          format: 'element',
                          prompt: 'Select',
                        },
                        templateOptions: {
                          required: false,
                          disabled: false,
                          options: [
                            {
                              name: 'Monday',
                            },
                            {
                              name: 'Tuesday',
                            },
                            {
                              name: 'Wednesday',
                            },
                          ],
                          valueProp: 'name',
                          label: 'Monthly - On the - Day',
                        },
                        className: 'col-xs-12 col-sm-12',
                        key: '9a150e49-4923-4843-8161-12f3aa375632',
                        defaultValue: 'Monday',
                      },
                    ],
                    data: {
                      format: 'group',
                      label: 'Monthly - On the',
                      key: '5d0ec49a-b2ac-46fb-8f3f-675fea016060',
                      rule: {
                        key: 'f11c5488-b398-43bd-b4c9-756efad764ee',
                        value: '2',
                        action: 'show',
                      },
                    },
                    templateOptions: {
                      valueProp: 'name',
                    },
                    className: 'row',
                    wrapper: ['field-group'],
                    hideExpression:
                      "model['f11c5488-b398-43bd-b4c9-756efad764ee'].toString() !== '2'",
                  },
                ],
                data: {
                  format: 'group',
                  label: 'Monthly Group',
                  key: 'efc63a16-370e-4450-8ee1-f489346ef563',
                  rule: {
                    key: '013191dc-7d44-4443-8a5d-2de4fb6f199c',
                    value: 'Monthly',
                    action: 'show',
                  },
                },
                templateOptions: {
                  valueProp: 'name',
                },
                className: 'row',
                wrapper: ['field-group'],
                hideExpression:
                  "model['013191dc-7d44-4443-8a5d-2de4fb6f199c'].toString() !== 'Monthly'",
              },
              {
                fieldGroup: [
                  {
                    type: 'input',
                    data: {
                      format: 'element',
                    },
                    templateOptions: {
                      required: false,
                      disabled: false,
                      options: [],
                      valueProp: 'name',
                      label: 'Every x week(s)',
                      placeholder: '',
                    },
                    className: 'col-xs-12 col-sm-12',
                    key: 'f1bfd671-8263-4a77-93c4-570bb196b382',
                    defaultValue: '1',
                  },
                  {
                    type: 'tlrMultiCheckbox',
                    data: {
                      format: 'element',
                    },
                    templateOptions: {
                      required: false,
                      disabled: false,
                      options: [
                        {
                          name: 'Monday',
                        },
                        {
                          name: 'Tuesday',
                        },
                        {
                          name: 'Wednesday',
                        },
                      ],
                      valueProp: 'name',
                      label: 'Weekly - Days',
                    },
                    className: 'col-xs-12 col-sm-12',
                    key: '2019e480-3c8f-4b49-8314-47ac24131638',
                  },
                ],
                data: {
                  format: 'group',
                  label: 'Weekly Group',
                  key: '3d635b81-7838-4b7d-8b0b-f83c8c1c105d',
                  rule: {
                    key: '013191dc-7d44-4443-8a5d-2de4fb6f199c',
                    value: 'Weekly',
                    action: 'show',
                  },
                },
                templateOptions: {
                  valueProp: 'name',
                },
                className: 'row',
                wrapper: ['field-group'],
                hideExpression:
                  "model['013191dc-7d44-4443-8a5d-2de4fb6f199c'].toString() !== 'Weekly'",
              },
              {
                fieldGroup: [
                  {
                    type: 'input',
                    data: {
                      format: 'element',
                    },
                    templateOptions: {
                      required: false,
                      disabled: false,
                      options: [],
                      valueProp: 'name',
                      label: 'Every x day(s)',
                      placeholder: '',
                    },
                    className: 'col-xs-12 col-sm-12',
                    key: '53e44060-3c07-4abf-b1f0-48a2d3519890',
                    defaultValue: '1',
                  },
                ],
                data: {
                  format: 'group',
                  label: 'Daily Group',
                  key: '5b00c1eb-868f-43d1-8994-8cdc9ce2cc15',
                  rule: {
                    key: '013191dc-7d44-4443-8a5d-2de4fb6f199c',
                    value: 'Daily',
                    action: 'show',
                  },
                },
                templateOptions: {
                  valueProp: 'name',
                },
                className: 'row',
                wrapper: ['field-group'],
                hideExpression:
                  "model['013191dc-7d44-4443-8a5d-2de4fb6f199c'].toString() !== 'Daily'",
              },
              {
                type: 'hr',
                data: {
                  format: 'element',
                },
                templateOptions: {
                  required: false,
                  disabled: false,
                  options: [],
                  valueProp: 'name',
                  label: 'HR2',
                },
                className: 'col-xs-12 col-sm-12',
                key: 'fe0aec57-f43a-4ad4-b1f9-7b3623101e2b',
              },
              {
                fieldGroup: [
                  {
                    type: 'tlrSelect',
                    data: {
                      format: 'element',
                      prompt: 'Select',
                    },
                    templateOptions: {
                      required: false,
                      disabled: false,
                      options: [
                        {
                          name: 'Never',
                        },
                        {
                          name: 'After',
                        },
                        {
                          name: 'On date',
                        },
                      ],
                      valueProp: 'name',
                      label: 'End',
                    },
                    className: 'col-xs-12 col-sm-12',
                    key: 'd52d6a06-5eed-484a-81bb-7b2777b68a24',
                    defaultValue: 'Never',
                  },
                  {
                    fieldGroup: [
                      {
                        type: 'input',
                        data: {
                          format: 'element',
                        },
                        templateOptions: {
                          required: false,
                          disabled: false,
                          options: [],
                          valueProp: 'name',
                          label: 'Executions',
                        },
                        className: 'col-xs-12 col-sm-12',
                        key: '1ef9159c-53d6-416d-8a6c-bc0f9e5bb94a',
                        defaultValue: '1',
                      },
                    ],
                    data: {
                      format: 'group',
                      label: 'End - After',
                      key: 'f947c05b-78c8-4837-8e63-a895c94458be',
                      rule: {
                        key: 'd52d6a06-5eed-484a-81bb-7b2777b68a24',
                        value: 'After',
                        action: 'show',
                      },
                    },
                    templateOptions: {
                      valueProp: 'name',
                    },
                    className: 'row',
                    wrapper: ['field-group'],
                    hideExpression:
                      "model['d52d6a06-5eed-484a-81bb-7b2777b68a24'].toString() !== 'After'",
                  },
                  {
                    fieldGroup: [
                      {
                        type: 'datepicker',
                        data: {
                          format: 'element',
                        },
                        templateOptions: {
                          required: false,
                          disabled: false,
                          options: [],
                          valueProp: 'name',
                          label: 'Date',
                        },
                        className: 'col-xs-12 col-sm-12',
                        key: '989d514a-106c-4041-99d4-6ac9ea6d92e2',
                      },
                    ],
                    data: {
                      format: 'group',
                      label: 'End - On date',
                      key: '86ee93a1-879a-44c9-89b6-ae482031f572',
                      rule: {
                        key: 'd52d6a06-5eed-484a-81bb-7b2777b68a24',
                        value: 'On date',
                        action: 'show',
                      },
                    },
                    templateOptions: {
                      valueProp: 'name',
                    },
                    className: 'row',
                    wrapper: ['field-group'],
                    hideExpression:
                      "model['d52d6a06-5eed-484a-81bb-7b2777b68a24'].toString() !== 'On date'",
                  },
                ],
                data: {
                  format: 'group',
                  label: 'End Group',
                  key: '75cc3bec-f5bc-4b18-8374-8f11121952c1',
                },
                templateOptions: {
                  valueProp: 'name',
                },
                className: 'row',
                wrapper: ['field-group'],
              },
            ],
          },
        ],
      };
    case 'task-note':
      return {
        fields: [
          {
            id: 'note-module',
            type: 'hidden',
            key: 'module',
          },
          {
            id: 'note-task-id',
            type: 'hidden',
            key: 'taskId',
          },
          {
            id: 'note-note',
            type: 'textarea',
            templateOptions: {
              required: true,
              label: 'Add note',
            },
            className: 'col-xs-12 col-sm-12',
            key: 'note',
          },
        ],
      };
    case 'create-site':
      return {
        fields: [
          {
            id: 'name',
            type: 'input',
            key: 'name',
            templateOptions: {
              required: true,
              label: 'Site name',
            },
          },
          {
            id: 'externalId',
            type: 'input',
            key: 'externalId',
            templateOptions: {
              required: false,
              label: 'External ID',
            },
          },
        ],
      };
    case 'edit-site-name':
      return {
        fields: [
          {
            id: 'name',
            type: 'input',
            key: 'name',
            templateOptions: {
              required: true,
              label: 'Site name',
            },
          },
        ],
      };
    case 'add-folder':
      return {
        fields: [
          {
            id: 'name',
            type: 'input',
            key: 'name',
            templateOptions: {
              required: true,
              label: 'Folder name',
            },
          },
        ],
      };
    case 'admin-view-user':
      return {
        fields: [
          {
            id: 'firstName',
            type: 'input',
            templateOptions: {
              label: 'First name',
            },
            key: 'firstName',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'lastName',
            type: 'input',
            templateOptions: {
              label: 'Last name',
            },
            key: 'lastName',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'email',
            type: 'input',
            templateOptions: {
              label: 'Email address',
            },
            key: 'email',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'role',
            type: 'input',
            templateOptions: {
              label: 'Role',
            },
            key: 'role',
            className: 'col-xs-12 col-sm-12',
          },
        ],
      };
    default:
      return { fields: [] };
  }
}

