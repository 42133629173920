import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import HorizontalLine from 'components/Shared/HorizontalLine';
import React from 'react';
import { useLocation } from 'react-router';
import TasksCalendar from './CalendarView/TasksCalendar';
import TasksList from './ListView/TasksList';
import TasksBreadcrumbs from './TasksBreadcrumbs';

export default function Tasks() {
  const queryString = new URLSearchParams(useLocation().search);
  const view = queryString.get('view');
  // Default to list view if we don't recognise the value
  const tasksView = view === 'calendar' ? <TasksCalendar /> : <TasksList />;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2" noWrap display="block">
            Tasks
          </Typography>
          <TasksBreadcrumbs title="Tasks" />
        </Grid>
      </Grid>
      <HorizontalLine margin={[2, 0, 2, 0]} />
      {tasksView}
    </>
  );
}
