import { Box, Typography } from '@material-ui/core';
import { ResponsivePie } from '@nivo/pie';
import ErrorDisplay from 'components/ErrorDisplay';
import React from 'react';
import { convertDataValueToString, DataColumn, DataRow } from '../utils';

interface IProps {
  columns: DataColumn[];
  data: DataRow[];
  groupByColumnName: string;
  countColumnName: string | undefined;
}

export default function PieChartView(props: IProps) {
  const { columns, data, groupByColumnName, countColumnName } = props;

  const groupByColumnType = columns.find((x) => x.name === groupByColumnName)?.type;
  if (!groupByColumnType) {
    return <ErrorDisplay errorMessage={`[${groupByColumnName}] is not a valid column.`} />;
  }
  if (countColumnName) {
    const countColumnType = columns.find((x) => x.name === countColumnName)?.type;
    if (countColumnType !== 'number') {
      return <ErrorDisplay errorMessage={`[${countColumnName}] is not a valid count column.`} />;
    }
  }

  const results: Record<string, { count: number; colorIndex: number }> = {};
  let colorIndex = 0;
  data.forEach((row) => {
    const key = convertDataValueToString(row[groupByColumnName], groupByColumnType);
    const count = countColumnName ? (row[countColumnName] as number) : 1;
    if (!results[key]) {
      results[key] = { count, colorIndex: (colorIndex += 1) };
    } else {
      results[key].count += count;
    }
  });

  const pieData = Object.keys(results).map((x) => ({
    id: x,
    value: results[x].count,
  }));

  const fills = ['', 'dots', 'lines'];

  return (
    <Box height={400} padding={3}>
      <Typography variant="h2">{groupByColumnName}</Typography>
      {pieData.length > 0 ? (
        <ResponsivePie
          animate
          arcLinkLabelsDiagonalLength={8}
          arcLinkLabelsStraightLength={8}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsThickness={2}
          arcLinkLabelsTextColor="#111111"
          activeOuterRadiusOffset={8}
          arcLinkLabelsColor={{ from: 'color' }}
          borderWidth={0}
          data={pieData}
          colors={{ scheme: 'accent' }}
          fill={pieData.map((x, i) => ({
            match: { id: x.id },
            id: fills[i % fills.length],
          }))}
          innerRadius={0.5}
          margin={{
            top: 40,
            right: 40,
            bottom: 40,
            left: 40,
          }}
        />
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Typography>No data.</Typography>
        </Box>
      )}
    </Box>
  );
}
