import { useCreateFileNote } from 'apollo-hooks';
import ErrorDisplay from 'components/ErrorDisplay';
import MagicForm from 'components/MagicForm';
import ModuleContext from 'components/Modules/ModuleContext';
import ModuleRedirect from 'components/Modules/ModuleRedirect';
import SiteContext from 'components/Sites/SiteContext';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { CreateFileNoteMutationVariables } from 'tillr-graphql';

export default function AddFileNote(props: RouteComponentProps<{ fileId: string }>) {
  const {
    match: {
      params: { fileId },
    },
  } = props;

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const [createFileNote, { loading, data, error }] = useCreateFileNote({
    siteId,
    module,
    id: fileId,
  });

  if (data?.createFileNote) {
    return <ModuleRedirect to={`/files/${fileId}`} />;
  }

  const handleSubmit = (model: CreateFileNoteMutationVariables) => {
    createFileNote({ variables: model });
  };

  const model: CreateFileNoteMutationVariables = {
    siteId,
    module,
    fileId,
    note: '',
  };

  return (
    <div>
      <h2>Create file note</h2>
      {error && <ErrorDisplay error={error} />}
      <MagicForm model={model} onSubmit={handleSubmit} submitting={loading} />
    </div>
  );
}
