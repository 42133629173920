import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import EmptyMessage from 'components/Shared/EmptyMessage';
import UserAvatar from 'components/Shared/UserAvatar';
import { format } from 'date-fns';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { TaskListItemFragment } from 'tillr-graphql';
import UserProfileContext from 'UserProfileContext';
import AssigneesSummary from './AssigneesSummary';
import DaysRemaining from './DaysRemaining';
import QuickStatus from './QuickStatus';
import QuickViewButton from './QuickViewButton';
// eslint-disable-next-line import/extensions
import { IAppTheme } from 'themes/useGetTheme';

const useStyles = makeStyles((theme: IAppTheme) => ({
  automated: {
    backgroundColor: theme.automatedTasks?.defaultColor,
  },
}));

export default function ResultsTable(props: { tasks: TaskListItemFragment[] }) {
  const classes = useStyles();
  const { tasks } = props;
  const history = useHistory();
  const userProfile = useContext(UserProfileContext);

  if (!tasks || !tasks.length) {
    return <EmptyMessage />;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { taskId } = event.currentTarget.dataset;
    if (taskId) {
      history.push(`tasks/${taskId}`);
    }
  };

  const renderQuickStatus = (x: any) => {
    if (x.open) {
      // TODO: May have to change if we start using Teams again
      if (userProfile?.canEditTask(x.createdByUser.id, x.assignedToUsers)) {
        return <QuickStatus taskId={x.id} />;
      }
      return (
        <IconButton disabled>
          <CheckBoxOutlineBlankIcon />
        </IconButton>
      );
    }
    return (
      <IconButton disabled>
        <CheckBoxIcon />
      </IconButton>
    );
  };

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <Hidden smDown>
                <TableCell>Ref</TableCell>
              </Hidden>
              <TableCell>Assignee(s)</TableCell>
              <Hidden smDown>
                <TableCell>Creator</TableCell>
              </Hidden>
              <Hidden smDown>
                <TableCell>Closed</TableCell>
              </Hidden>
              <Hidden smDown>
                <TableCell>Due</TableCell>
              </Hidden>
              <TableCell>Due date</TableCell>
              <Hidden smDown>
                <TableCell align="right">Actions</TableCell>
              </Hidden>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((x) => (
              <TableRow key={x.id} hover>
                <TableCell className="user-clickable" data-task-id={x.id} onClick={handleClick}>
                  <div className="like-list-item-avatar">
                    <Hidden smDown>
                      <Avatar className={x.createdByFormTemplateName ? classes.automated : ''}>
                        <AssignmentTurnedInIcon />
                      </Avatar>
                    </Hidden>
                    <div className="like-list-item-avatar__text">
                      <Typography variant="body1" display="block">
                        {x.name}
                      </Typography>
                      {x.createdByFormTemplateName && (
                        <Typography variant="body2" display="block">
                          {x.createdByFormTemplateName}
                        </Typography>
                      )}
                    </div>
                  </div>
                </TableCell>
                <Hidden smDown>
                  <TableCell className="user-clickable" data-task-id={x.id} onClick={handleClick}>
                    {x.referenceNumber}
                  </TableCell>
                </Hidden>
                <TableCell className="user-clickable" data-task-id={x.id} onClick={handleClick}>
                  <AssigneesSummary users={x.assignedToUsers} teams={x.assignedToTeams} />
                </TableCell>
                <Hidden smDown>
                  <TableCell>
                    <UserAvatar name={x.createdByUser.name} id={x.createdByUser.id} />
                  </TableCell>
                </Hidden>
                <Hidden smDown>
                  <TableCell>{renderQuickStatus(x)}</TableCell>
                </Hidden>
                <Hidden smDown>
                  <TableCell className="user-clickable" data-task-id={x.id} onClick={handleClick}>
                    <DaysRemaining open={x.open} completedDate={x.completedDate} date={x.dueDate} />
                  </TableCell>
                </Hidden>
                <TableCell className="user-clickable" data-task-id={x.id} onClick={handleClick}>
                  {format(new Date(x.dueDate), 'dd/MM/yyyy')}
                </TableCell>
                <Hidden smDown>
                  <TableCell align="right">
                    <QuickViewButton taskId={x.id} />
                  </TableCell>
                </Hidden>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
