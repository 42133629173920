import React, { useContext, useEffect } from 'react';
import UserManagerContext from 'UserManagerContext';

export default function Logout() {
  const userManager = useContext(UserManagerContext)!;

  useEffect(() => {
    userManager.signoutRedirect();
  }, [userManager]);

  return <span />;
}
