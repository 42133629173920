import Button from '@material-ui/core/Button';
import React, { useContext } from 'react';
import UserManagerContext from 'UserManagerContext';
import UserProfileContext from 'UserProfileContext';

export default function AuthControls() {
  const userManager = useContext(UserManagerContext)!;
  const userProfile = useContext(UserProfileContext)!;

  if (!userProfile) {
    return (
      <Button variant="contained" color="primary" onClick={() => userManager.signinRedirect()}>
        Continue
      </Button>
    );
  }

  return <span />;
}
