import { Grid, Paper, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useGetFormTemplates, useGetFormTemplatesByTag } from 'apollo-hooks';
import useDetectTenantType from 'components/Custom/useDetectTenantType';
import DataTable from 'components/DataViews/DataTable';
import ErrorDisplay from 'components/ErrorDisplay';
import FilterControl, { FilterType, IFilter } from 'components/Filtering/FilterControl';
import ModuleContext from 'components/Modules/ModuleContext';
import PaginationControl from 'components/Pagination/PaginationControl';
import PreviousNextControl from 'components/Pagination/PreviousNextControl';
import HorizontalLine from 'components/Shared/HorizontalLine';
import ListControls from 'components/Shared/ListControls';
import ProgressBar from 'components/Shared/ProgressBar';
import SiteContext from 'components/Sites/SiteContext';
import React, { useContext, useState } from 'react';
import {
  FormTemplateFilterBy,
  FormTemplateOrderBy,
  FormTemplatePaginationPropsType,
  PaginationOrder,
} from 'tillr-graphql';
import FormBreadcrumbs from './FormBreadcrumbs';
import LoadingFormTemplates from './LoadingFormTemplates';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(2),
      },
    },
  }),
);

const filterTypeMappings = new Map([[FormTemplateFilterBy.Name, FilterType.String]]);

export default function FormTemplatesByTag2() {
  const classes = useStyles();
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const paginationOptions = {
    pageSizes: [5, 10, 100],
    orderBys: Object.values(FormTemplateOrderBy),
    orders: Object.values(PaginationOrder),
  };
  const paginationProps = {
    pageSize: paginationOptions.pageSizes[paginationOptions.pageSizes.length - 1],
    orderBy: paginationOptions.orderBys[0],
    order: paginationOptions.orders[0],
  };
  const [tenantType] = useDetectTenantType();

  // TODO - All of this is temp / smoke and mirrors
  const [tagValue, setTagValue] = useState('');
  const tagging = useGetFormTemplatesByTag({ module, tagValue });
  const tagLoading = tagging.loading;
  const tagData = tagging.data;

  const { loading, error, data, refetch, fetchMore } = useGetFormTemplates({
    siteId,
    module,
    paginationProps,
  });

  const handleChangeFilter = (nextFilter: IFilter<FormTemplateFilterBy>) => {
    refetch({ filterProps: { filters: [nextFilter] } });
  };

  const handleChangePaginationProps = (nextPaginationProps: FormTemplatePaginationPropsType) => {
    refetch({ paginationProps: nextPaginationProps });
  };

  const handleFetchPrevious = () => {
    fetchMore({ variables: { before: data?.formTemplates?.startCursor } });
  };

  const handleFetchNext = () => {
    fetchMore({ variables: { after: data?.formTemplates?.endCursor } });
  };

  const handleTagValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagValue(event.target.value);
  };

  // TODO: Hiding the Compliance Status form templates that is used
  // in the Dashboard. This is not how it should be done.
  function FormTemplates(formTemplatesProps: any) {
    const { forms } = formTemplatesProps;
    const formTemplates = forms.filter(
      (template: any) => template.id !== 'fd52a49e-6942-426c-8bf3-3754dd360bcc',
    );
    return <DataTable data={formTemplates} itemRoute="form-templates/{id}/forms" />;
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2" noWrap display="block">
            Forms
          </Typography>
          <FormBreadcrumbs title="Forms" />
        </Grid>
      </Grid>
      <HorizontalLine margin={[2, 0, 2, 0]} />
      {loading && <ProgressBar />}
      {error && <ErrorDisplay error={error} />}
      {data?.formTemplates && (
        <>
          <Paper>
            <Grid container>
              <Grid item sm={3} xs={12} className={classes.textField}>
                {tenantType === 'squirrel' && (
                  <TextField
                    label="Filter by tag"
                    onChange={handleTagValueChange}
                    value={tagValue}
                  />
                )}
              </Grid>
              <Grid item sm={9} xs={12}>
                <ListControls>
                  <PaginationControl<FormTemplateOrderBy>
                    initialProps={paginationProps}
                    options={paginationOptions}
                    onRefetch={handleChangePaginationProps}
                  />
                  <FilterControl<FormTemplateFilterBy>
                    filterByValues={Object.values(FormTemplateFilterBy)}
                    filterTypeMappings={filterTypeMappings}
                    onChange={handleChangeFilter}
                  />
                  <PreviousNextControl
                    hasPreviousPage={data.formTemplates.hasPreviousPage}
                    onFetchPrevious={handleFetchPrevious}
                    hasNextPage={data.formTemplates.hasNextPage}
                    onFetchNext={handleFetchNext}
                  />
                </ListControls>
              </Grid>
            </Grid>
            <HorizontalLine margin={[1, 0, 1, 0]} />
            {tagLoading && <LoadingFormTemplates />}
            {!tagLoading && !!tagValue?.length && tagData?.taggedEntities && (
              <FormTemplates forms={tagData.taggedEntities} />
            )}
            {!tagLoading && !tagValue?.length && !tagData?.taggedEntities?.length && (
              <FormTemplates forms={data.formTemplates.items} />
            )}
            <ListControls>
              <PreviousNextControl
                hasPreviousPage={data.formTemplates.hasPreviousPage}
                onFetchPrevious={handleFetchPrevious}
                hasNextPage={data.formTemplates.hasNextPage}
                onFetchNext={handleFetchNext}
              />
            </ListControls>
          </Paper>
        </>
      )}
    </>
  );
}
