import { Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useCreateFile, useGetFileType } from 'apollo-hooks';
import ActivityIndicator from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import ModuleContext from 'components/Modules/ModuleContext';
import ModuleRedirect from 'components/Modules/ModuleRedirect';
import SiteContext from 'components/Sites/SiteContext';
import FormlyForm from 'formly/FormlyForm';
import { IModel } from 'formly/IModel';
import React, { useContext, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { CreateFileMutationVariables, CreateFormInputType } from 'tillr-graphql';
import { generateUuid } from 'utils';
import FileBreadcrumbs from './FileBreadcrumbs';
import { buildFormlyConfig, splitModel } from './utils';

export default function AddFile(props: RouteComponentProps<{ fileTypeId: string }>) {
  const {
    match: {
      params: { fileTypeId },
    },
  } = props;

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const queryState = useGetFileType({ module, id: fileTypeId });
  const [createFile, mutationState] = useCreateFile();
  const [formId] = useState(generateUuid());

  if (mutationState.data?.createFile) {
    return <ModuleRedirect to={`/file-types/${fileTypeId}`} />;
  }

  const handleSubmit = (model: IModel) => {
    const [name, formDataJson] = splitModel(model);
    const form: CreateFormInputType = {
      templateId: queryState?.data?.fileType?.formTemplate.id,
      name,
      formDataJson,
      isSubmitted: false,
    };

    const variables: CreateFileMutationVariables = {
      siteId,
      module,
      fileTypeId,
      form,
    };
    createFile({ variables });
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2" noWrap display="block">
            Create New TODO
          </Typography>
          <FileBreadcrumbs fileTypeId={fileTypeId} title="Create New TODO" />
        </Grid>
      </Grid>
      <Divider className="divider" />
      {queryState.loading && <ActivityIndicator />}
      {queryState.error && <ErrorDisplay error={queryState.error} />}
      {mutationState.error && <ErrorDisplay error={mutationState.error} />}
      {queryState.data?.fileType?.formTemplate && (
        <FormlyForm
          formId={formId}
          mode="create"
          config={buildFormlyConfig(queryState.data.fileType.formTemplate.template)}
          onSubmit={handleSubmit}
          submitting={mutationState.loading}
        />
      )}
    </div>
  );
}
