/* eslint-disable react/jsx-one-expression-per-line */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDeleteTag } from 'apollo-hooks';
import ActivityIndicator from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import React, { useState } from 'react';
import { DeleteTagMutationVariables } from 'tillr-graphql';

const useStyles = makeStyles(() =>
  createStyles({
    deleteIcon: {
      color: '#fff',
      padding: 0,
      margin: '0 5px 0 -6px',
    },
  }),
);

interface IProps {
  tagLabel: string;
  tagId: string;
  onTagDeleted?: (tagId: string) => void;
}

export default function DeleteTagControl(props: IProps) {
  const classes = useStyles();
  const { tagLabel, tagId, onTagDeleted } = props;
  const [open, setOpen] = useState(false);

  const [deleteTag, { loading, error, data, reset }] = useDeleteTag();

  const handleDelete = () => {
    const variables: DeleteTagMutationVariables = { tagId };
    deleteTag({ variables });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (data?.deleteTag) {
    if (onTagDeleted) {
      onTagDeleted(data.deleteTag.id);
      handleClose();
    }
    reset();
  }

  return (
    <>
      <IconButton className={classes.deleteIcon} onClick={handleClickOpen}>
        <CancelIcon />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">Remove tag</DialogTitle>
        <DialogContent>
          {loading && <ActivityIndicator />}
          {error && <ErrorDisplay error={error} />}
          {!loading && !error && (
            <Typography variant="body1" noWrap display="block">
              Are you certain you want to remove the tag &apos;{tagLabel}&apos; from this file?
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} disabled={loading} color="primary">
            Yes, remove &apos;{tagLabel}&apos;
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
