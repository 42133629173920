import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { IComponentProps } from 'formly/IComponentProps';
import React, { useEffect } from 'react';
import ElementInfo from './shared/ElementInfo';
import HelpText from './shared/HelpText';
import { FieldValidationMessage, getRuleMessage, validationStyles } from './shared/Validation';

export default function SingleCheckbox(props: IComponentProps) {
  const validationClasses = validationStyles();
  const { field, readonly, modelValue, onValueUpdate, register, errors, setValue, trigger } = props;

  useEffect(() => {
    register(
      { name: field.key },
      { required: { value: field.templateOptions?.required, message: getRuleMessage('required') } },
    );
    setValue(field.key, !!modelValue);
    if (modelValue) {
      trigger(field.key!);
    }
  }, [register, field, modelValue, setValue, trigger]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (field.key) {
      onValueUpdate(field.key, event.currentTarget.checked);
      setValue(field.key!, !!event.currentTarget.checked);
      trigger(field.key!);
    }
  };

  return (
    <>
      {field.className?.match(/clear/) && (
        <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
      )}
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={
          field.className?.match(/pull-right/)
            ? 'tillr-form-grid-element tillr-form-grid-element--align-right'
            : 'tillr-form-grid-element'
        }
      >
        <FormControl component="fieldset" disabled={readonly} error={!!errors[field.key!]}>
          <FormControlLabel
            checked={!!modelValue}
            control={
              <Checkbox
                onChange={handleChange}
                id={field.key}
                required={!readonly && field.templateOptions?.required}
              />
            }
            label={
              !readonly && field.templateOptions?.required ? (
                <>
                  {field.templateOptions?.label}
                  <span className={validationClasses.asterisk}> *</span>
                  <ElementInfo inline data={field.data} />
                </>
              ) : (
                <>
                  {field.templateOptions?.label}
                  <ElementInfo inline data={field.data} />
                </>
              )
            }
          />
          {errors[field.key!] && (
            <FieldValidationMessage className="non-input" message={errors[field.key!].message} />
          )}
          {field.data?.help && <HelpText value={field.data?.help} />}
        </FormControl>
      </Grid>
    </>
  );
}

