import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useNetworkStatus } from 'react-network-status';
// eslint-disable-next-line import/extensions
import Content from 'components/Content/Content';
import Nav from 'components/Nav/Nav';
import OfflineMessage from 'components/Shared/OfflineMessage';
import { NetworkConfig } from 'NetworkStatus';
import NetworkStatusContext from 'NetworkStatusContext';
import useGetTheme from 'themes/useGetTheme';

export default function Layout() {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [networkStatus, setNetworkStatus] = useState<boolean>(true);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const theme = useGetTheme();

  useNetworkStatus((networkStatusUpdate) => {
    setNetworkStatus(networkStatusUpdate);
  }, NetworkConfig);

  return (
    <>
      <CssBaseline />
      <div className="tillr">
        <MuiThemeProvider theme={theme}>
          <NetworkStatusContext.Provider value={networkStatus}>
            <OfflineMessage />
            <Nav drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
            <Content drawerOpen={drawerOpen} toggleDrawerOpen={toggleDrawer} />
          </NetworkStatusContext.Provider>
        </MuiThemeProvider>
      </div>
    </>
  );
}

