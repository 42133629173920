import Typography from '@material-ui/core/Typography';
import { useGetMyNotificationPreferences } from 'apollo-hooks';
import ActivityIndicator from 'components/ActivityIndicator';
import DataTable from 'components/DataViews/DataTable';
import ErrorDisplay from 'components/ErrorDisplay';
import React from 'react';
import { Link } from 'react-router-dom';

export default function NotificationPreferences() {
  const { loading, error, data } = useGetMyNotificationPreferences();

  return (
    <div>
      <Typography variant="h2" noWrap>
        Notification preferences
      </Typography>
      <p>
        <Link to="/notification-preferences/edit">Edit</Link>
      </p>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data?.myNotificationPreferences && (
        <>
          <h3>My preferences</h3>
          <DataTable data={data.myNotificationPreferences} />
        </>
      )}
      {data?.notificationTypes && (
        <>
          <h3>All notification types</h3>
          <DataTable data={data.notificationTypes} />
        </>
      )}
    </div>
  );
}
