/* eslint-disable max-len */
import { DataProxy, useMutation, useQuery } from '@apollo/client';
import { useResettableMutation } from 'apollo-hooks-extended';
import { useContext } from 'react';
import {
  AssignFormTemplateToTenantModulesDocument,
  AssignFormTemplateToTenantModulesMutation,
  AssignReportToTenantModulesDocument,
  AssignReportToTenantModulesMutation,
  CreateAttachmentsDocument,
  CreateAttachmentsMutation,
  CreateFileDocument,
  CreateFileMutation,
  CreateFileNoteDocument,
  CreateFileNoteMutation,
  CreateFormDocument,
  CreateFormMutation,
  CreateInvitationDocument,
  CreateInvitationMutation,
  CreateLibraryFilesDocument,
  CreateLibraryFilesMutation,
  CreateLibraryFolderDocument,
  CreateLibraryFolderMutation,
  CreateReportDocument,
  CreateReportMutation,
  CreateRoleDocument,
  CreateRoleMutation,
  CreateSiteDocument,
  CreateSiteMutation,
  CreateTagDocument,
  CreateTagMutation,
  CreateTaskDocument,
  CreateTaskMutation,
  CreateTaskNoteDocument,
  CreateTaskNoteMutation,
  DeleteAttachmentDocument,
  DeleteAttachmentMutation,
  DeleteLibraryFileDocument,
  DeleteLibraryFileMutation,
  DeleteLibraryFolderDocument,
  DeleteLibraryFolderMutation,
  DeleteTagDocument,
  DeleteTagMutation,
  GetAttachmentDetailDocument,
  GetAttachmentDetailQuery,
  GetAttachmentDetailQueryVariables,
  GetAttachmentUrlDocument,
  GetAttachmentUrlQuery,
  GetAttachmentUrlQueryVariables,
  GetBuildNumberDocument,
  GetBuildNumberQuery,
  GetDownloadAttachmentUrlTemplateDocument,
  GetDownloadAttachmentUrlTemplateQuery,
  GetFileTypeDocument,
  GetFileTypeQuery,
  GetFileTypeQueryVariables,
  GetFileTypesDocument,
  GetFileTypesQuery,
  GetFileTypesQueryVariables,
  GetFormDocument,
  GetFormQuery,
  GetFormQueryVariables,
  GetFormsDocument,
  GetFormsQuery,
  GetFormsQueryVariables,
  GetFormTemplateAndInstancesDocument,
  GetFormTemplateAndInstancesQuery,
  GetFormTemplateAndInstancesQueryVariables,
  GetFormTemplateDocument,
  GetFormTemplateQuery,
  GetFormTemplateQueryDefinitionDocument,
  GetFormTemplateQueryDefinitionQuery,
  GetFormTemplateQueryDefinitionQueryVariables,
  GetFormTemplateQueryVariables,
  GetFormTemplateRulesDocument,
  GetFormTemplateRulesQuery,
  GetFormTemplateRulesQueryVariables,
  GetFormTemplatesByTagDocument,
  GetFormTemplatesByTagQuery,
  GetFormTemplatesByTagQueryVariables,
  GetFormTemplatesDocument,
  GetFormTemplatesQuery,
  GetFormTemplatesQueryVariables,
  GetLibraryFilesDocument,
  GetLibraryFilesQuery,
  GetLibraryFilesQueryVariables,
  GetLibraryFolderDocument,
  GetLibraryFolderQuery,
  GetLibraryFolderQueryVariables,
  GetMyFileDocument,
  GetMyFileQuery,
  GetMyFileQueryVariables,
  GetMyFilesDocument,
  GetMyFilesQuery,
  GetMyFilesQueryVariables,
  GetMyNotificationPreferencesDocument,
  GetMyNotificationPreferencesQuery,
  GetMyReportDocument,
  GetMyReportQuery,
  GetMyReportQueryVariables,
  GetMyReportsDocument,
  GetMyReportsQuery,
  GetMyReportsQueryVariables,
  GetMySitesDocument,
  GetMySitesQuery,
  GetMyTaskDocument,
  GetMyTaskQuery,
  GetMyTaskQueryVariables,
  GetMyTasksDocument,
  GetMyTasksQuery,
  GetMyTasksQueryVariables,
  GetMyTenantDocument,
  GetMyTenantQuery,
  GetRolesDocument,
  GetRolesQuery,
  GetSitesAdminDocument,
  GetSitesAdminQuery,
  GetSystemFormTemplatesDocument,
  GetSystemFormTemplatesQuery,
  GetSystemFormTemplatesQueryVariables,
  GetSystemReportDocument,
  GetSystemReportQuery,
  GetSystemReportQueryVariables,
  GetSystemReportsDocument,
  GetSystemReportsQuery,
  GetTagsDocument,
  GetTagsQuery,
  GetTeamsAndUsersDocument,
  GetTeamsAndUsersQuery,
  GetTeamsAndUsersQueryVariables,
  GetTeamsDocument,
  GetTeamsQuery,
  GetTeamsQueryVariables,
  GetTeamWithUsersDocument,
  GetTeamWithUsersQuery,
  GetTeamWithUsersQueryVariables,
  GetTenantDocument,
  GetTenantQuery,
  GetTenantQueryVariables,
  GetTenantReportsDocument,
  GetTenantReportsQuery,
  GetTenantReportWithUsersDocument,
  GetTenantReportWithUsersQuery,
  GetTenantReportWithUsersQueryVariables,
  GetTenantsDocument,
  GetTenantsQuery,
  GetTenantWithFormTemplatesDocument,
  GetTenantWithFormTemplatesQuery,
  GetTenantWithFormTemplatesQueryVariables,
  GetTenantWithReportsDocument,
  GetTenantWithReportsQuery,
  GetTenantWithReportsQueryVariables,
  GetTenantWithRolesDocument,
  GetTenantWithRolesQuery,
  GetTenantWithRolesQueryVariables,
  GetUserAdminDocument,
  GetUserAdminQuery,
  GetUserAdminQueryVariables,
  GetUsersAdminDocument,
  GetUsersAdminQuery,
  Module,
  ParentType,
  UpdateAttachmentDocument,
  UpdateAttachmentMutation,
  UpdateFileDocument,
  UpdateFileMutation,
  UpdateFormDocument,
  UpdateFormMutation,
  UpdateMyNotificationPreferencesDocument,
  UpdateMyNotificationPreferencesMutation,
  UpdateMyTaskDocument,
  UpdateMyTaskMutation,
  UpdateReportDocument,
  UpdateReportMutation,
  UpdateReportUsersDocument,
  UpdateReportUsersMutation,
  UpdateRoleDocument,
  UpdateRoleMutation,
  UpdateSiteDocument,
  UpdateSiteMutation,
  UpdateUserDocument,
  UpdateUserMutation,
} from 'tillr-graphql';
import UserProfileContext from './UserProfileContext';

// This deleteCacheForQuery is a hack until it's supported in Apollo Client
interface DataProxyExt extends DataProxy {
  data?: { data?: { ROOT_QUERY: { [key: string]: string } } };
}
const deleteCacheForQuery = (queryName: string) => (proxy: DataProxyExt) => {
  if (proxy.data?.data?.ROOT_QUERY) {
    Object.keys(proxy.data.data?.ROOT_QUERY)
      .filter((x) => x.split(':')[0] === queryName)
      // eslint-disable-next-line no-param-reassign
      .forEach((x) => delete proxy.data!.data!.ROOT_QUERY[x]);
  }
};

export function useAssignFormTemplateToTenantModules(
  variables: GetTenantWithFormTemplatesQueryVariables,
) {
  return useResettableMutation<AssignFormTemplateToTenantModulesMutation>(
    AssignFormTemplateToTenantModulesDocument,
    {
      onError: () => {},
      update(cache, { data: updatedData, errors }) {
        if (!errors?.length && updatedData?.assignFormTemplateToTenantModules) {
          const updatedFormTemplate = updatedData.assignFormTemplateToTenantModules;

          const options = { query: GetTenantWithFormTemplatesDocument, variables };
          const existingData = cache.readQuery<GetTenantWithFormTemplatesQuery>(options);
          if (existingData?.tenant) {
            const formTemplatesNext = existingData.tenant.formTemplates?.filter(
              (x) => x.id !== updatedFormTemplate.id,
            );
            formTemplatesNext?.push(updatedFormTemplate);
            const tenantNext = {
              ...existingData.tenant,
              formTemplates: formTemplatesNext,
            };
            cache.writeQuery({
              query: GetTenantWithFormTemplatesDocument,
              variables,
              data: { tenant: tenantNext },
            });
          }
        }
      },
    },
  );
}

export function useAssignReportToTenantModules(variables: GetTenantWithReportsQueryVariables) {
  return useResettableMutation<AssignReportToTenantModulesMutation>(
    AssignReportToTenantModulesDocument,
    {
      onError: () => {},
      update(cache, { data: updatedData, errors }) {
        if (!errors?.length && updatedData?.assignReportToTenantModules) {
          const updatedReport = updatedData.assignReportToTenantModules;

          const options = { query: GetTenantWithReportsDocument, variables };
          const existingData = cache.readQuery<GetTenantWithReportsQuery>(options);
          if (existingData?.tenant) {
            const reportsNext = existingData.tenant.reports?.filter(
              (x) => x.id !== updatedReport.id,
            );
            reportsNext?.push(updatedReport);
            const tenantNext = {
              ...existingData.tenant,
              reports: reportsNext,
            };
            cache.writeQuery({
              query: GetTenantWithReportsDocument,
              variables,
              data: { tenant: tenantNext },
            });
          }
        }
      },
    },
  );
}

export function useCreateAttachments(props: {
  siteId: number;
  module: Module;
  parentId: string;
  parentType: ParentType;
}) {
  return useMutation<CreateAttachmentsMutation>(CreateAttachmentsDocument, {
    onError: () => {},
    update(cache, { data: updatedData, errors }) {
      if (!errors?.length && updatedData?.createAttachments) {
        const { attachments } = updatedData.createAttachments;
        const { siteId, module, parentId, parentType } = props;
        switch (parentType) {
          case ParentType.File: {
            for (let i = 0; i < attachments.length; i += 1) {
              cache.writeQuery({
                query: GetAttachmentDetailDocument,
                variables: { id: attachments[i].id } as GetAttachmentDetailQueryVariables,
                data: { attachment: attachments[i] },
              });
            }

            const variables: GetMyFileQueryVariables = { siteId, module, id: parentId };
            const existingData = cache.readQuery<GetMyFileQuery>({
              query: GetMyFileDocument,
              variables,
            });
            if (existingData?.myFile?.attachments) {
              const myFileNext = {
                ...existingData.myFile,
                attachments: existingData.myFile.attachments.concat(attachments),
              };
              cache.writeQuery({
                query: GetMyFileDocument,
                variables,
                data: { myFile: myFileNext },
              });
            }
            break;
          }
          case ParentType.Form: {
            break;
          }
          case ParentType.Task: {
            const variables: GetMyTaskQueryVariables = { siteId, module, id: parentId };
            const existingData = cache.readQuery<GetMyTaskQuery>({
              query: GetMyTaskDocument,
              variables,
            });
            if (existingData?.myTask?.attachments) {
              const myTaskNext = {
                ...existingData.myTask,
                attachments: existingData.myTask.attachments.concat(attachments),
              };
              cache.writeQuery({
                query: GetMyTaskDocument,
                variables,
                data: { myTask: myTaskNext },
              });
            }
            break;
          }
          default: {
            throw new Error(`Unknown parentType: ${parentType}`);
          }
        }
      }
    },
  });
}

export function useCreateFile() {
  return useMutation<CreateFileMutation>(CreateFileDocument, {
    onError: () => {},
    // Files list is paginated, delete from cache so it will refetch
    update: deleteCacheForQuery('myFiles'),
  });
}

export function useCreateFileNote(variables: GetMyFileQueryVariables) {
  return useMutation<CreateFileNoteMutation>(CreateFileNoteDocument, {
    onError: () => {},
    update(cache, { data: updatedData, errors }) {
      if (!errors?.length && updatedData?.createFileNote) {
        const options = { query: GetMyFileDocument, variables };
        const existingData = cache.readQuery<GetMyFileQuery>(options);
        if (existingData?.myFile) {
          const myFileNext = {
            ...existingData.myFile,
            fileNotes: existingData.myFile.fileNotes.concat([updatedData.createFileNote]),
          };
          cache.writeQuery({
            query: GetMyFileDocument,
            variables,
            data: { myFile: myFileNext },
          });
        }
      }
    },
  });
}

export function useCreateForm() {
  return useMutation<CreateFormMutation>(CreateFormDocument, {
    onError: () => {},
    // Forms list is paginated, delete from cache so it will refetch
    update: deleteCacheForQuery('forms'),
  });
}

export function useCreateInvitation() {
  return useMutation<CreateInvitationMutation>(CreateInvitationDocument, {
    onError: () => {},
    update(cache, { data: updatedData, errors }) {
      if (!errors?.length && updatedData?.createInvitation) {
        const options = { query: GetUsersAdminDocument };
        const existingData = cache.readQuery<GetUsersAdminQuery>(options);
        if (existingData?.pendingInvitations) {
          cache.writeQuery({
            query: GetUsersAdminDocument,
            data: {
              pendingInvitations: existingData.pendingInvitations.concat([
                updatedData.createInvitation,
              ]),
            },
          });
        }
      }
    },
  });
}

export function useCreateLibraryFiles(variables: GetLibraryFolderQueryVariables) {
  return useMutation<CreateLibraryFilesMutation>(CreateLibraryFilesDocument, {
    onError: () => {},
    update(cache, { data: updatedData, errors }) {
      if (!errors?.length && updatedData?.createLibraryFiles) {
        const options = { query: GetLibraryFolderDocument, variables };
        const existingData = cache.readQuery<GetLibraryFolderQuery>(options);
        if (existingData?.libraryFolder) {
          const libraryFolderNext = {
            ...existingData.libraryFolder,
            files: existingData.libraryFolder.files.concat(updatedData.createLibraryFiles.files),
          };
          cache.writeQuery({
            query: GetLibraryFolderDocument,
            variables,
            data: { libraryFolder: libraryFolderNext },
          });
        }
      }
    },
  });
}

export function useCreateLibraryFolder(variables: GetLibraryFolderQueryVariables) {
  return useResettableMutation<CreateLibraryFolderMutation>(CreateLibraryFolderDocument, {
    onError: () => {},
    update(cache, { data: updatedData, errors }) {
      if (!errors?.length && updatedData?.createLibraryFolder) {
        const options = { query: GetLibraryFolderDocument, variables };
        const existingData = cache.readQuery<GetLibraryFolderQuery>(options);
        if (existingData?.libraryFolder) {
          const libraryFolderNext = {
            ...existingData.libraryFolder,
            folders: existingData.libraryFolder.folders.concat([updatedData.createLibraryFolder]),
          };
          cache.writeQuery({
            query: GetLibraryFolderDocument,
            variables,
            data: { libraryFolder: libraryFolderNext },
          });
        }
      }
    },
  });
}

export function useCreateReport() {
  return useResettableMutation<CreateReportMutation>(CreateReportDocument, {
    onError: () => {},
    update(cache, { data: updatedData, errors }) {
      if (!errors?.length && updatedData?.createReport) {
        const options = { query: GetSystemReportsDocument };
        const existingData = cache.readQuery<GetSystemReportsQuery>(options);
        if (existingData?.systemReports) {
          cache.writeQuery({
            query: GetSystemReportsDocument,
            data: {
              systemReports: existingData.systemReports.concat([updatedData.createReport]),
            },
          });
        }
      }
    },
  });
}

export function useCreateRole(variables: GetTenantWithRolesQueryVariables) {
  return useMutation<CreateRoleMutation>(CreateRoleDocument, {
    onError: () => {},
    update(cache, { data: updatedData, errors }) {
      if (!errors?.length && updatedData?.createRole) {
        const options = { query: GetTenantWithRolesDocument, variables };
        const existingData = cache.readQuery<GetTenantWithRolesQuery>(options);
        if (existingData?.tenant) {
          const tenantNext = {
            ...existingData.tenant,
            roles: (existingData.tenant.roles || []).concat([updatedData.createRole]),
          };
          cache.writeQuery({
            ...options,
            data: { tenant: tenantNext },
          });
        }
      }
    },
  });
}

export function useCreateSite() {
  return useMutation<CreateSiteMutation>(CreateSiteDocument, {
    onError: () => {},
    update(cache, { data: updatedData, errors }) {
      if (!errors?.length && updatedData?.createSite) {
        const options = { query: GetSitesAdminDocument };
        const existingData = cache.readQuery<GetSitesAdminQuery>(options);
        if (existingData?.sitesAdmin) {
          cache.writeQuery({
            query: GetSitesAdminDocument,
            data: { sitesAdmin: existingData.sitesAdmin.concat([updatedData.createSite]) },
          });
        }
      }
    },
  });
}

export function useCreateTag() {
  return useResettableMutation<CreateTagMutation>(CreateTagDocument, {
    onError: () => {},
    update(cache, { data: updatedData, errors }) {
      if (!errors?.length && updatedData?.createTag) {
        const options = { query: GetTagsDocument };
        const existingData = cache.readQuery<GetTagsQuery>(options);
        if (existingData?.tags && existingData.tags.indexOf(updatedData.createTag.value) < 0) {
          cache.writeQuery({
            query: GetTagsDocument,
            data: { tags: existingData.tags.concat([updatedData.createTag.value]) },
          });
        }
      }
    },
  });
}

export function useCreateTask() {
  return useResettableMutation<CreateTaskMutation>(CreateTaskDocument, {
    onError: () => {},
    // Tasks list is paginated, delete from cache so it will refetch
    update: deleteCacheForQuery('myTasks'),
  });
}

export function useCreateTaskNote(variables: GetMyTaskQueryVariables) {
  return useMutation<CreateTaskNoteMutation>(CreateTaskNoteDocument, {
    onError: () => {},
    update(cache, { data: updatedData, errors }) {
      if (!errors?.length && updatedData?.createTaskNote) {
        const options = { query: GetMyTaskDocument, variables };
        const existingData = cache.readQuery<GetMyTaskQuery>(options);
        if (existingData?.myTask) {
          const myTaskNext = {
            ...existingData.myTask,
            notes: existingData.myTask.notes.concat([updatedData.createTaskNote]),
          };
          cache.writeQuery({
            query: GetMyTaskDocument,
            variables,
            data: { myTask: myTaskNext },
          });
        }
      }
    },
  });
}

export function useDeleteAttachment(props: {
  siteId: number;
  module: Module;
  parentId: string;
  parentType: ParentType;
}) {
  return useMutation<DeleteAttachmentMutation>(DeleteAttachmentDocument, {
    onError: () => {},
    update(cache, { data, errors }) {
      if (!errors?.length && data?.deleteAttachment) {
        const { id: deletedId } = data.deleteAttachment;
        const { siteId, module, parentId, parentType } = props;
        switch (parentType) {
          case ParentType.File: {
            const variables: GetMyFileQueryVariables = { siteId, module, id: parentId };
            const existingData = cache.readQuery<GetMyFileQuery>({
              query: GetMyFileDocument,
              variables,
            });
            if (existingData?.myFile?.attachments) {
              const myFileNext = {
                ...existingData.myFile,
                attachments: existingData.myFile.attachments.filter((x) => x.id !== deletedId),
              };
              cache.writeQuery({
                query: GetMyFileDocument,
                variables,
                data: { myFile: myFileNext },
              });
            }
            break;
          }
          case ParentType.Form: {
            break;
          }
          case ParentType.Task: {
            const variables: GetMyTaskQueryVariables = { siteId, module, id: parentId };
            const existingData = cache.readQuery<GetMyTaskQuery>({
              query: GetMyTaskDocument,
              variables,
            });
            if (existingData?.myTask?.attachments) {
              const myTaskNext = {
                ...existingData.myTask,
                attachments: existingData.myTask.attachments.filter((x) => x.id !== deletedId),
              };
              cache.writeQuery({
                query: GetMyTaskDocument,
                variables,
                data: { myTask: myTaskNext },
              });
            }
            break;
          }
          default: {
            throw new Error(`Unknown parentType: ${parentType}`);
          }
        }
      }
    },
  });
}

export function useDeleteLibraryFile(variables: GetLibraryFolderQueryVariables) {
  return useResettableMutation<DeleteLibraryFileMutation>(DeleteLibraryFileDocument, {
    onError: () => {},
    update(cache, { data: updatedData, errors }) {
      if (!errors?.length && updatedData?.deleteLibraryFile) {
        const options = { query: GetLibraryFolderDocument, variables };
        const existingData = cache.readQuery<GetLibraryFolderQuery>(options);
        if (existingData?.libraryFolder) {
          const libraryFolderNext = {
            ...existingData.libraryFolder,
            files: existingData.libraryFolder.files.filter(
              (x) => x.id !== updatedData.deleteLibraryFile?.id,
            ),
          };
          cache.writeQuery({
            query: GetLibraryFolderDocument,
            variables,
            data: { libraryFolder: libraryFolderNext },
          });
        }
      }
    },
  });
}

export function useDeleteLibraryFolder(variables: GetLibraryFolderQueryVariables) {
  return useResettableMutation<DeleteLibraryFolderMutation>(DeleteLibraryFolderDocument, {
    onError: () => {},
    update(cache, { data: updatedData, errors }) {
      if (!errors?.length && updatedData?.deleteLibraryFolder) {
        const options = { query: GetLibraryFolderDocument, variables };
        const existingData = cache.readQuery<GetLibraryFolderQuery>(options);
        if (existingData?.libraryFolder) {
          const libraryFolderNext = {
            ...existingData.libraryFolder,
            folders: existingData.libraryFolder.folders.filter(
              (x) => x.id !== updatedData.deleteLibraryFolder?.id,
            ),
          };
          cache.writeQuery({
            query: GetLibraryFolderDocument,
            variables,
            data: { libraryFolder: libraryFolderNext },
          });
        }
      }
    },
  });
}

export function useDeleteTag() {
  return useResettableMutation<DeleteTagMutation>(DeleteTagDocument, {
    onError: () => {},
  });
}

export function useGetAttachmentDetail(variables: GetAttachmentDetailQueryVariables) {
  return useQuery<GetAttachmentDetailQuery>(GetAttachmentDetailDocument, { variables });
}

export function useGetAttachmentUrl(variables: GetAttachmentUrlQueryVariables) {
  return useQuery<GetAttachmentUrlQuery>(GetAttachmentUrlDocument, { variables });
}

export function useGetBuildNumber() {
  return useQuery<GetBuildNumberQuery>(GetBuildNumberDocument);
}

export function useGetDownloadAttachmentUrlTemplate() {
  return useQuery<GetDownloadAttachmentUrlTemplateQuery>(GetDownloadAttachmentUrlTemplateDocument);
}

export function useGetFileTypes(variables: GetFileTypesQueryVariables) {
  const userProfile = useContext(UserProfileContext);
  const loadFileTypes = userProfile && userProfile.hasAnyPermission(['Files.View']);
  return useQuery<GetFileTypesQuery>(GetFileTypesDocument, { skip: !loadFileTypes, variables });
}

export function useGetFileType(variables: GetFileTypeQueryVariables) {
  return useQuery<GetFileTypeQuery>(GetFileTypeDocument, { variables });
}

export function useGetFormTemplate(variables: GetFormTemplateQueryVariables) {
  return useQuery<GetFormTemplateQuery>(GetFormTemplateDocument, { variables });
}

export function useGetFormTemplateQueryDefinition(
  variables: GetFormTemplateQueryDefinitionQueryVariables,
) {
  return useQuery<GetFormTemplateQueryDefinitionQuery>(GetFormTemplateQueryDefinitionDocument, {
    skip: !variables.id,
    variables,
  });
}

export function useGetFormTemplateRules(variables: GetFormTemplateRulesQueryVariables) {
  return useQuery<GetFormTemplateRulesQuery>(GetFormTemplateRulesDocument, { variables });
}

export function useGetFormTemplates(variables: GetFormTemplatesQueryVariables) {
  return useQuery<GetFormTemplatesQuery>(GetFormTemplatesDocument, { variables });
}

export function useGetFormTemplatesByTag(variables: GetFormTemplatesByTagQueryVariables) {
  return useQuery<GetFormTemplatesByTagQuery>(GetFormTemplatesByTagDocument, { variables });
}

export function useGetLibraryFiles(variables: GetLibraryFilesQueryVariables) {
  return useQuery<GetLibraryFilesQuery>(GetLibraryFilesDocument, {
    skip: !variables.searchQuery,
    variables,
  });
}

export function useGetLibraryFolder(variables: GetLibraryFolderQueryVariables) {
  return useQuery<GetLibraryFolderQuery>(GetLibraryFolderDocument, { variables });
}

export function useGetMyFile(variables: GetMyFileQueryVariables) {
  return useQuery<GetMyFileQuery>(GetMyFileDocument, { variables });
}

export function useGetMyFiles(variables: GetMyFilesQueryVariables) {
  return useQuery<GetMyFilesQuery>(GetMyFilesDocument, { variables });
}

export function useGetForm(variables: GetFormQueryVariables) {
  return useQuery<GetFormQuery>(GetFormDocument, { variables });
}

export function useGetFormTemplateAndInstances(
  variables: GetFormTemplateAndInstancesQueryVariables,
) {
  return useQuery<GetFormTemplateAndInstancesQuery>(GetFormTemplateAndInstancesDocument, {
    variables,
  });
}

export function useGetForms(variables: GetFormsQueryVariables) {
  return useQuery<GetFormsQuery>(GetFormsDocument, { variables });
}

export function useGetMyNotificationPreferences() {
  return useQuery<GetMyNotificationPreferencesQuery>(GetMyNotificationPreferencesDocument);
}

export function useGetMyReport(variables: GetMyReportQueryVariables) {
  return useQuery<GetMyReportQuery>(GetMyReportDocument, { variables });
}

export function useGetMyReports(variables: GetMyReportsQueryVariables) {
  return useQuery<GetMyReportsQuery>(GetMyReportsDocument, { variables });
}

export function useGetMySites(hasMultipleSites: boolean) {
  return useQuery<GetMySitesQuery>(GetMySitesDocument, { skip: !hasMultipleSites });
}

export function useGetMyTask(variables: GetMyTaskQueryVariables) {
  return useQuery<GetMyTaskQuery>(GetMyTaskDocument, { variables });
}

export function useGetMyTasks(variables: GetMyTasksQueryVariables) {
  return useQuery<GetMyTasksQuery>(GetMyTasksDocument, { variables });
}

export function useGetMyTenant() {
  return useQuery<GetMyTenantQuery>(GetMyTenantDocument);
}

export function useGetRoles() {
  return useQuery<GetRolesQuery>(GetRolesDocument);
}

export function useGetSitesAdmin() {
  return useQuery<GetSitesAdminQuery>(GetSitesAdminDocument);
}

export function useGetSystemFormTemplates(variables: GetSystemFormTemplatesQueryVariables) {
  return useQuery<GetSystemFormTemplatesQuery>(GetSystemFormTemplatesDocument, { variables });
}

export function useGetSystemReport(variables: GetSystemReportQueryVariables) {
  return useQuery<GetSystemReportQuery>(GetSystemReportDocument, { variables });
}

export function useGetSystemReports() {
  return useQuery<GetSystemReportsQuery>(GetSystemReportsDocument);
}

export function useGetTags() {
  return useQuery<GetTagsQuery>(GetTagsDocument);
}

export function useGetTeams(variables: GetTeamsQueryVariables) {
  return useQuery<GetTeamsQuery>(GetTeamsDocument, { variables });
}

export function useGetTeamsAndUsers(variables: GetTeamsAndUsersQueryVariables) {
  return useQuery<GetTeamsAndUsersQuery>(GetTeamsAndUsersDocument, { variables });
}

export function useGetTeamWithUsers(variables: GetTeamWithUsersQueryVariables) {
  return useQuery<GetTeamWithUsersQuery>(GetTeamWithUsersDocument, { variables });
}

export function useGetTenantReportWithUsers(variables: GetTenantReportWithUsersQueryVariables) {
  return useQuery<GetTenantReportWithUsersQuery>(GetTenantReportWithUsersDocument, { variables });
}

export function useGetTenantReports() {
  return useQuery<GetTenantReportsQuery>(GetTenantReportsDocument);
}

export function useGetTenantWithFormTemplates(variables: GetTenantWithFormTemplatesQueryVariables) {
  return useQuery<GetTenantWithFormTemplatesQuery>(GetTenantWithFormTemplatesDocument, {
    variables,
  });
}

export function useGetTenantWithReports(variables: GetTenantWithReportsQueryVariables) {
  return useQuery<GetTenantWithReportsQuery>(GetTenantWithReportsDocument, { variables });
}

export function useGetTenantWithRoles(variables: GetTenantWithRolesQueryVariables) {
  return useQuery<GetTenantWithRolesQuery>(GetTenantWithRolesDocument, { variables });
}

export function useGetTenant(variables: GetTenantQueryVariables) {
  return useQuery<GetTenantQuery>(GetTenantDocument, { variables });
}

export function useGetTenants() {
  return useQuery<GetTenantsQuery>(GetTenantsDocument);
}

export function useGetUserAdmin(variables: GetUserAdminQueryVariables) {
  return useQuery<GetUserAdminQuery>(GetUserAdminDocument, { variables });
}

export function useGetUsersAdmin() {
  return useQuery<GetUsersAdminQuery>(GetUsersAdminDocument);
}

export function useUpdateAttachment(variables: GetAttachmentDetailQueryVariables) {
  return useMutation<UpdateAttachmentMutation>(UpdateAttachmentDocument, {
    onError: () => {},
    update(cache, { data, errors }) {
      if (!errors?.length && data?.updateAttachment) {
        const getAttachmentDetailOptions = { query: GetAttachmentDetailDocument, variables };
        const existingAttachmentDetail = cache.readQuery<GetAttachmentDetailQuery>(
          getAttachmentDetailOptions,
        );
        if (existingAttachmentDetail?.attachment) {
          cache.writeQuery({
            query: GetAttachmentDetailDocument,
            variables,
            data: { attachment: data.updateAttachment },
          });
        }
      }
    },
  });
}

export function useUpdateFile(variables: GetMyFileQueryVariables) {
  return useMutation<UpdateFileMutation>(UpdateFileDocument, {
    onError: () => {},
    update(cache, { data, errors }) {
      if (!errors?.length && data?.updateFile) {
        const getMyFileOptions = { query: GetMyFileDocument, variables };
        const existingFormData = cache.readQuery<GetMyFileQuery>(getMyFileOptions);
        if (existingFormData?.myFile) {
          cache.writeQuery({
            query: GetMyFileDocument,
            variables,
            data: { myFile: data.updateFile },
          });
        }
      }
    },
  });
}

export function useUpdateForm(variables: GetFormQueryVariables) {
  return useMutation<UpdateFormMutation>(UpdateFormDocument, {
    onError: () => {},
    update(cache, { data, errors }) {
      if (!errors?.length && data?.updateForm) {
        const options = { query: GetFormDocument, variables };
        const existingFormData = cache.readQuery<GetFormQuery>(options);
        if (existingFormData?.form) {
          cache.writeQuery({
            query: GetFormDocument,
            variables,
            data: { form: data.updateForm },
          });
        }
      }
    },
  });
}

export function useUpdateMyNotificationPreferences() {
  return useMutation<UpdateMyNotificationPreferencesMutation>(
    UpdateMyNotificationPreferencesDocument,
    {
      onError: () => {},
      update(cache, { data: updatedData, errors }) {
        if (!errors?.length && updatedData?.updateMyNotificationPreferences) {
          const options = { query: GetMyNotificationPreferencesDocument };
          const existingData = cache.readQuery<GetMyNotificationPreferencesQuery>(options);
          if (existingData?.myNotificationPreferences) {
            cache.writeQuery({
              query: GetMyNotificationPreferencesDocument,
              data: { myNotificationPreferences: updatedData.updateMyNotificationPreferences },
            });
          }
        }
      },
    },
  );
}

export function useUpdateReport(variables: GetSystemReportQueryVariables) {
  return useResettableMutation<UpdateReportMutation>(UpdateReportDocument, {
    onError: () => {},
    update(cache, { data, errors }) {
      if (!errors?.length && data?.updateReport) {
        const options = { query: GetSystemReportDocument, variables };
        const existingData = cache.readQuery<GetSystemReportQuery>(options);
        if (existingData?.systemReport) {
          cache.writeQuery({
            query: GetSystemReportDocument,
            variables,
            data: { systemReport: data.updateReport },
          });
        }
      }
    },
  });
}

export function useUpdateMyTask(variables: GetMyTaskQueryVariables) {
  return useMutation<UpdateMyTaskMutation>(UpdateMyTaskDocument, {
    onError: () => {},
    update(cache, { data, errors }) {
      if (!errors?.length && data?.updateMyTask) {
        const options = { query: GetMyTaskDocument, variables };
        const existingTaskData = cache.readQuery<GetMyTaskQuery>(options);
        if (existingTaskData?.myTask) {
          cache.writeQuery({
            query: GetMyTaskDocument,
            variables,
            data: { myTask: data.updateMyTask },
          });
        }
      }
    },
  });
}

export function useUpdateSite() {
  return useMutation<UpdateSiteMutation>(UpdateSiteDocument, {
    onError: () => {},
  });
}

export function useUpdateReportUsers() {
  return useMutation<UpdateReportUsersMutation>(UpdateReportUsersDocument, {
    onError: () => {},
  });
}

export function useUpdateRole() {
  return useMutation<UpdateRoleMutation>(UpdateRoleDocument, {
    onError: () => {},
  });
}

export function useUpdateUser(variables: GetUserAdminQueryVariables) {
  return useMutation<UpdateUserMutation>(UpdateUserDocument, {
    onError: () => {},
    update(cache, { data, errors }) {
      if (!errors?.length && data?.updateUser) {
        const options = { query: GetUserAdminDocument, variables };
        const existingData = cache.readQuery<GetUserAdminQuery>(options);
        if (existingData?.userAdmin) {
          cache.writeQuery({
            query: GetUserAdminDocument,
            variables,
            data: { userAdmin: data.updateUser },
          });
        }
      }
    },
  });
}
