import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { format } from 'date-fns';
import React, { useContext } from 'react';
import UserProfileContext from 'UserProfileContext';
import AddNote from './AddNote';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    note: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
    },
    noteAuthor: {
      textAlign: 'right',
    },
  }),
);

interface INote {
  note: string;
  createdDateTime: string;
  user: {
    id: string;
    name: string;
  };
}

interface INotes {
  notes: INote[];
  taskId: string;
  onNoteAdded: () => void;
  permission?: string[];
}

export default function NotesList(props: INotes) {
  const classes = useStyles();
  const userProfile = useContext(UserProfileContext);
  const { notes, taskId, onNoteAdded, permission = [] } = props;

  return (
    <Card>
      <CardContent>
        <h2>Notes</h2>
        {userProfile?.hasAnyPermission(permission) && (
          <AddNote taskId={taskId} onCreated={onNoteAdded} />
        )}
        {notes.length ? (
          <>
            {notes.map((note) => (
              <div key={note.createdDateTime}>
                <div className={classes.note}>{note.note}</div>
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <p className={classes.noteAuthor}>
                  Added by <strong>{note.user.name}</strong> at{' '}
                  {format(new Date(note.createdDateTime), "hh:mm aaaaa'm' 'on' EEEE do MMMM yyyy")}
                </p>
              </div>
            ))}
          </>
        ) : (
          <p>No notes have been added to this task.</p>
        )}
      </CardContent>
    </Card>
  );
}
