import { Box, List, ListItem, Typography } from '@material-ui/core';
import ModuleLinkButton from 'components/Modules/ModuleLinkButton';
import SiteContext from 'components/Sites/SiteContext';
import React, { useContext } from 'react';

export type QuickLinkProps = {
  label: string;
  type: string;
  args: Array<any>;
  includeSiteIds?: Array<number>;
  excludeSiteIds?: Array<number>;
};

interface IProps {
  links: Array<QuickLinkProps>;
}

export default function QuickLinks(props: IProps) {
  const { links } = props;

  const siteContext = useContext(SiteContext);

  const linksToShow = siteContext
    ? links.filter(
        (link) =>
          (!link.includeSiteIds || link.includeSiteIds.includes(siteContext.siteId)) &&
          (!link.excludeSiteIds || !link.excludeSiteIds.includes(siteContext.siteId)),
      )
    : null;

  if (linksToShow === null || linksToShow.length === 0) {
    return null;
  }

  return (
    <Box mt={3}>
      <Typography variant="h2">Quick links</Typography>
      <List>
        {linksToShow.map((link, i) => (
          <ListItem key={i}>
            <ModuleLinkButton to={`/form-templates/${link.args[0]}/forms/add`}>
              {link.label}
            </ModuleLinkButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
