import Typography from '@material-ui/core/Typography';
import { useGetFormTemplateRules } from 'apollo-hooks';
import ActivityIndicator from 'components/ActivityIndicator';
import Detail from 'components/DataViews/Detail';
import ErrorDisplay from 'components/ErrorDisplay';
import ModuleContext from 'components/Modules/ModuleContext';
import SiteContext from 'components/Sites/SiteContext';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

export default function Rules(props: RouteComponentProps<{ formTemplateId: string }>) {
  const {
    match: {
      params: { formTemplateId },
    },
  } = props;

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const { loading, error, data } = useGetFormTemplateRules({ siteId, module, id: formTemplateId });

  return (
    <div>
      <Typography variant="h2" noWrap>
        Rules
      </Typography>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data &&
        (data.formTemplate ? (
          <Detail data={data.formTemplate} />
        ) : (
          !error && (
            <p>
              <i>Not found</i>
            </p>
          )
        ))}
    </div>
  );
}
