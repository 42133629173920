import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useGetMyTask, useUpdateMyTask } from 'apollo-hooks';
import ErrorDisplay from 'components/ErrorDisplay';
import ModuleContext from 'components/Modules/ModuleContext';
import ModuleLinkButton from 'components/Modules/ModuleLinkButton';
import ModuleRedirect from 'components/Modules/ModuleRedirect';
import NotesList from 'components/Notes/NotesList';
import ActionButtons from 'components/Shared/ActionButtons';
import CancelButton from 'components/Shared/CancelButton';
import HorizontalLine from 'components/Shared/HorizontalLine';
import ProgressBar from 'components/Shared/ProgressBar';
import ProgressButton from 'components/Shared/ProgressButton';
import SiteContext from 'components/Sites/SiteContext';
import { IAssignee } from 'formly/components/Assignees';
import FormlyForm from 'formly/FormlyForm';
import { IModel } from 'formly/IModel';
import getStaticTemplate from 'formly/staticTemplates';
import React, { useContext, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { AssigneeType, UpdateMyTaskMutationVariables, UpdateTaskInputType } from 'tillr-graphql';
import UserProfileContext from 'UserProfileContext';
import TasksBreadcrumbs from './TasksBreadcrumbs';
import { formatTaskAssignees } from './utils';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      paddingTop: 0,
    },
    prompt: {
      marginTop: 0,
    },
  }),
);

interface ITaskNotePromptProps {
  open: boolean;
  model: {
    note: string;
  };
  changed: string[];
}

export default function EditTask(props: RouteComponentProps<{ taskId: string }>) {
  const classes = useStyles();
  const {
    match: {
      params: { taskId },
    },
  } = props;
  const [taskNotePrompt, setTaskNotePrompt] = useState<ITaskNotePromptProps>({
    open: false,
    model: { note: '' },
    changed: [],
  });
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const userProfile = useContext(UserProfileContext)!;
  const { loading, error, data, refetch } = useGetMyTask({ siteId, module, id: taskId });
  const [updateMyTask, mutationState] = useUpdateMyTask({ siteId, module, id: taskId });
  const [changedModel, setChangedModel] = useState<IModel>({});

  if (mutationState.data?.updateMyTask) {
    return <ModuleRedirect to={`/tasks/${taskId}`} />;
  }

  const handleSave = (model: IModel) => {
    const task: UpdateTaskInputType = {
      id: taskId,
      name: model.name,
      description: model.description,
      open: model.open,
      completedDate: model.completedDate,
      assignees: model.assignees.map((x: IAssignee) => ({
        assigneeId: x.id,
        assigneeType: x.type === 'User' ? AssigneeType.User : AssigneeType.Team,
      })),
    };

    const mutationVariables: UpdateMyTaskMutationVariables = {
      siteId,
      module,
      task,
      note: taskNotePrompt.model.note,
    };
    updateMyTask({ variables: mutationVariables });
  };

  const handleSubmit = (model: IModel) => {
    if (data && data.myTask) {
      const task = formatTaskAssignees(data?.myTask);
      if (
        task.open !== model.open ||
        task.description !== model.description ||
        task.name !== model.name ||
        JSON.stringify(task.assignees) !== JSON.stringify(model.assignees)
      ) {
        setTaskNotePrompt((prev) => ({
          ...prev,
          open: true,
        }));
        setChangedModel(model);
        return;
      }
    }
    handleSave(model);
  };

  const handleNoteCreated = () => {
    refetch();
  };

  const handleCloseTaskNotePrompt = () => {
    setTaskNotePrompt({
      model: { note: '' },
      changed: [],
      open: false,
    });
  };

  const config = getStaticTemplate('task');

  const formattedConfig = {
    fields: config.fields.map((field) => {
      if (field.key === 'completedDate') {
        if (data?.myTask?.createdDateTime) {
          Object.defineProperty(field.data, 'minDate', {
            value: data.myTask.createdDateTime,
            writable: true,
            enumerable: true,
          });
        }
        Object.defineProperty(field.data, 'maxDate', {
          value: new Date(),
          writable: true,
          enumerable: true,
        });
        return field;
      }
      if (field.key === 'dueDate') {
        Object.defineProperty(field.templateOptions, 'disabled', {
          value: true,
          writable: false,
          enumerable: true,
        });
        return field;
      }
      return field;
    }),
  };

  return (
    <>
      {loading && <ProgressBar />}
      {error && <ErrorDisplay error={error} />}
      {mutationState.error && <ErrorDisplay error={mutationState.error} />}
      {data?.myTask && (
        <>
          {/* TODO: May have to change if we start using Teams again */}
          {!userProfile.canEditTask(data.myTask.createdByUser.id, data.myTask.assignedToUsers) ? (
            <ModuleRedirect to={`/tasks/${taskId}`} />
          ) : (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h2" noWrap display="block">
                    Tasks
                  </Typography>
                  <TasksBreadcrumbs title={data.myTask.name} />
                </Grid>
              </Grid>
              <HorizontalLine margin={[2, 0, 2, 0]} />
              <ActionButtons>
                <ModuleLinkButton
                  to={`/tasks/${taskId}`}
                  aria-label="cancel"
                  startIcon={<CloseIcon />}
                  tooltip="Cancel"
                >
                  Cancel
                </ModuleLinkButton>
              </ActionButtons>
              <FormlyForm
                formId={taskId}
                mode="manage"
                config={formattedConfig}
                model={formatTaskAssignees(data?.myTask)}
                onSubmit={handleSubmit}
              />
              <HorizontalLine margin={[4, 0, 4, 0]} />
              <NotesList
                permission={['Tasks.Edit']}
                onNoteAdded={handleNoteCreated}
                taskId={taskId}
                notes={data.myTask.notes}
              />
              <Dialog
                fullWidth
                maxWidth="sm"
                open={taskNotePrompt.open}
                onClose={handleCloseTaskNotePrompt}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">Add a note to this task</DialogTitle>
                <DialogContent className={classes.content}>
                  <p className={classes.prompt}>
                    You have made changes to this task. Please add a note to describe the changes
                    you have made.
                  </p>
                  <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} className="tillr-form-grid-element">
                        <TextField
                          id="optional-form-note"
                          name="optional-form-note"
                          defaultValue=""
                          label="Add note"
                          variant="outlined"
                          placeholder="Add note"
                          onChange={(e) => {
                            setTaskNotePrompt((prev) => ({
                              ...prev,
                              model: {
                                note: e.target.value,
                              },
                            }));
                          }}
                          fullWidth
                          multiline
                          rows={4}
                          rowsMax={8}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </DialogContent>
                <DialogActions className="flex-d-col flex-a-end">
                  {/* onCancel */}
                  <CancelButton
                    label="Close and continuing editing"
                    loading={loading}
                    onClick={() =>
                      setTaskNotePrompt(() => ({
                        model: { note: '' },
                        changed: [],
                        open: false,
                      }))
                    }
                  />
                  <ProgressButton
                    label="Save changes to this task"
                    loading={loading}
                    type="button"
                    onClick={() => handleSave(changedModel)}
                  />
                </DialogActions>
              </Dialog>
            </>
          )}
        </>
      )}
    </>
  );
}
