/* eslint-disable consistent-return */
import { ThemeProvider } from '@nivo/core';
import { BoxLegendSvg } from '@nivo/legends';
import { ResponsiveSunburst } from '@nivo/sunburst';
import { schemePaired } from 'd3-scale-chromatic';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@nivo/tooltip';
import { useGetMyTasks } from 'apollo-hooks';
import ErrorDisplay from 'components/ErrorDisplay';
import ModuleContext from 'components/Modules/ModuleContext';
import ProgressBar from 'components/Shared/ProgressBar';
import SiteContext from 'components/Sites/SiteContext';
import addDays from 'date-fns/addDays';
import isWithinInterval from 'date-fns/isWithinInterval';
import set from 'date-fns/set';
import React, { useContext } from 'react';
import { IAppTheme } from 'themes/useGetTheme';
import { PaginationOrder, TaskOrderBy } from 'tillr-graphql';
import UserProfileContext from 'UserProfileContext';

const useStyles = makeStyles((theme: IAppTheme) => ({
  dashboardText: {
    '& h2': {
      fontSize: 22,
      margin: theme.spacing(0, 0, 2, 0),
      padding: theme.spacing(0, 0, 1, 0),
      borderBottom: 'solid 1px #ccc',
      color: '#111',
    },
    '& p': {
      margin: theme.spacing(0, 0, 2, 0),
      fontSize: 12,
    },
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltip: {
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5, 1),
    alignItems: 'center',
  },
  tooltipText: {
    padding: theme.spacing(0, 0, 0, 1),
    fontWeight: 'bold',
  },
  label: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10em',
    fontWeight: 'bold',
    color: '#333333',
  },
}));

interface IAssignee {
  id: string;
  name: string;
}
interface ITask {
  id: string;
  name: string;
  referenceNumber: number;
  assignedToUsers: IAssignee[];
  assignedToTeams: IAssignee[];
  createdByFormTemplateName?: null | string;
  open: boolean;
  dueDate: string;
}

interface ITaskData {
  id: string;
  value: number | string | Date;
  children: ITaskData[];
  date?: string;
}

interface ICustomTooltipProps {
  color: string;
  id: string;
  value: number | string | Date;
  date?: string;
}

export default function TasksStatus() {
  const classes = useStyles();
  const contextValue = useContext(UserProfileContext);
  const userId = contextValue?.getUserId();
  const paginationOptions = {
    pageSizes: [9999],
    orderBys: Object.values(TaskOrderBy),
    orders: Object.values(PaginationOrder),
  };
  const paginationProps = {
    pageSize: paginationOptions.pageSizes[0],
    orderBy: paginationOptions.orderBys[0],
    order: paginationOptions.orders[0],
  };
  let totalTasks = 0;
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const { loading, error, data } = useGetMyTasks({ siteId, module, paginationProps });

  const renderChart = (tasks: ITask[]) => {
    const tasksData: ITaskData = {
      id: `tasks ${Math.random()}`,
      value: 0,
      children: [
        {
          id: 'Open tasks',
          value: 0,
          children: [],
        },
        {
          id: 'Closed tasks',
          value: 0,
          children: [],
        },
      ],
    };

    // eslint-disable-next-line array-callback-return
    tasks.filter((task: ITask) => {
      const dueDate = set(new Date(task.dueDate), { hours: 0, minutes: 0, seconds: 0 });
      const today = set(new Date(), { hours: 0, minutes: 0, seconds: 0 });
      if (isWithinInterval(dueDate, { start: today, end: addDays(today, 30) })) {
        const myTask = task.assignedToUsers.filter((assignee) => assignee.id === userId);
        if (myTask.length) {
          const t = {
            id: `${task.name}:::${Math.random()}`,
            value: '1',
            children: [],
            date: task.dueDate,
          };
          // eslint-disable-next-line max-len
          tasksData.children[task.open ? 0 : 1].children = tasksData.children[
            task.open ? 0 : 1
          ].children.concat([t]);
          totalTasks += 1;
          return task;
        }
      }
    });

    if (!totalTasks) {
      tasksData.children[0].value = 1;
    }

    function CustomToolTip(props: ICustomTooltipProps): JSX.Element | null {
      const { id, value, color } = props;
      const ttValue = id.indexOf(':::') !== -1 ? '' : value;
      if (!totalTasks) {
        return null;
      }
      return (
        <Box component="div" className={classes.tooltip}>
          <Chip color={color} />
          <span className={classes.tooltipText}>
            {id.split(':::')[0]}
            {` ${ttValue}`}
          </span>
        </Box>
      );
    }

    return (
      <>
        <ThemeProvider>
          <Box height={400} position="relative">
            <p className={classes.label}>{totalTasks}</p>
            <Box position="absolute">
              {/* // itemHeight + itemSpacing + default padding + some extra */}
              <svg height={tasksData.children?.length! * (14 + 4 + 2 + 10)}>
                <BoxLegendSvg
                  symbolSize={12}
                  anchor="top-left"
                  itemWidth={120}
                  itemHeight={14}
                  itemsSpacing={4}
                  // itemHeight + itemSpacing + default padding + some extra
                  containerHeight={tasksData.children?.length! * (14 + 4 + 2 + 10)}
                  containerWidth={240}
                  direction="column"
                  data={tasksData.children?.map((x, i) => ({
                    id: x.id!,
                    label: x.id!,
                    color: schemePaired[i],
                  }))}
                />
              </svg>
            </Box>
            <ResponsiveSunburst
              // @ts-ignore
              enableArcLabels={!!totalTasks}
              // eslint-disable-next-line consistent-return
              arcLabel={(o: any) => {
                if (o.depth === 1) {
                  return o.value;
                }
              }}
              arcLabelsSkipAngle={10}
              data={tasksData}
              margin={{
                top: 10,
                right: 0,
                bottom: 10,
                left: 0,
              }}
              // @ts-ignore
              tooltip={(props: any) => (
                <CustomToolTip
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                />
              )}
              cornerRadius={0}
              borderWidth={1}
              borderColor="white"
              colors={totalTasks ? { scheme: 'paired' } : ['#efefef']}
              childColor={{ from: 'color', modifiers: [['brighter', 0.5]] }}
              animate
              motionConfig="gentle"
            />
          </Box>
        </ThemeProvider>
      </>
    );
  };

  return (
    <div>
      <div className={classes.dashboardText}>
        <Typography variant="h2" display="block">
          Task Status
        </Typography>
        <Typography variant="body2" display="block">
          Tasks assigned to you, due within the next thirty days.
        </Typography>
      </div>
      {loading && (
        <Box height={400} className={classes.loading}>
          <ProgressBar />
          <CircularProgress />
        </Box>
      )}
      {error && <ErrorDisplay error={error} />}
      {data?.myTasks?.items && renderChart(data.myTasks.items)}
    </div>
  );
}
