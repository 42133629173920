import { Box } from '@material-ui/core';
import { ThemeProvider } from '@nivo/core';
import { BoxLegendSvg } from '@nivo/legends';
import { ResponsivePie } from '@nivo/pie';
import { schemePaired } from 'd3-scale-chromatic';
import { IFormlyConfig, IFormlyField } from 'formly/IFormlyConfig';
import { IModel } from 'formly/IModel';
import React, { useEffect, useState } from 'react';

interface IProps {
  config: IFormlyConfig;
  model: IModel;
}

interface IData {
  id: string;
  legend: string;
  value: number;
  fill: string;
  color: string;
}

export default function DocumentsStatus(props: IProps) {
  const { config, model } = props;
  const [data, setData] = useState<IData[]>([]);

  function generateData() {
    let requiredComplete = 0;
    let requiredIncomplete = 0;
    let notApplicable = 0;

    config.fields.forEach((field: IFormlyField) => {
      if (model[field?.key as string] === false) {
        notApplicable += 1;
      } else {
        // eslint-disable-next-line no-lonely-if
        if (field?.data?.completed?.length) {
          requiredComplete += 1;
        } else {
          requiredIncomplete += 1;
        }
      }
    });

    const d = [
      {
        id: 'Complete',
        legend: 'Complete',
        value: requiredComplete,
        fill: '',
        color: schemePaired[1],
      },
      {
        id: 'Incomplete',
        legend: 'Incomplete',
        value: requiredIncomplete,
        fill: '',
        color: schemePaired[5],
      },
      {
        id: 'Not applicable',
        legend: 'Not applicable',
        value: notApplicable,
        fill: '',
        color: '#cccccc',
      },
    ];

    setData(d);
  }

  useEffect(() => {
    if (model && config) {
      generateData();
    }
    // eslint-disable-next-line
  }, [model]);

  const renderChart = () => (
    <ThemeProvider>
      <Box height={400} position="relative">
        <Box position="absolute">
          <svg height={data?.length! * (14 + 4 + 2 + 10)}>
            <BoxLegendSvg
              symbolShape="square"
              symbolSize={12}
              anchor="top-left"
              padding={{ top: 0 }}
              itemWidth={120}
              itemHeight={14}
              itemsSpacing={4}
              containerHeight={200}
              containerWidth={240}
              direction="column"
              data={data?.map((x) => ({ id: x.id!, label: x.id!, color: x.color! }))}
            />
          </svg>
        </Box>
        <ResponsivePie
          animate
          enableArcLinkLabels={false}
          activeOuterRadiusOffset={8}
          borderWidth={0}
          data={data}
          arcLabelsSkipAngle={10}
          colors={[schemePaired[1], schemePaired[5], '#cccccc']}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          fill={data.map((x) => ({
            match: { id: x.id },
            id: x.fill,
          }))}
          innerRadius={0.5}
          margin={{
            top: data?.length! * 20,
            right: 0,
            bottom: data?.length! * 4,
            left: 0,
          }}
        />
      </Box>
    </ThemeProvider>
  );

  return <div>{data.length && renderChart()}</div>;
}
