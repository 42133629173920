import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import { useCreateLibraryFolder } from 'apollo-hooks';
import ErrorDisplay from 'components/ErrorDisplay';
import FormlyForm from 'formly/FormlyForm';
import getStaticTemplate from 'formly/staticTemplates';
import React, { useState } from 'react';
import { CreateLibraryFolderMutationVariables } from 'tillr-graphql';

interface IProps {
  folderId: string;
  folderName: string;
}

export default function AddFolder({ folderId, folderName }: IProps) {
  const [createLibraryFolder, { loading, error }] = useCreateLibraryFolder({ folderId });

  const [open, setOpen] = useState(false);

  const handleSubmit = (folder: any) => {
    const variables: CreateLibraryFolderMutationVariables = {
      name: folder.name,
      parentFolderId: folderId,
    };
    createLibraryFolder({ variables });
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tooltipValue = `Add a folder under ${folderName}`;

  const config = getStaticTemplate('add-folder');

  return (
    <>
      <Tooltip title={tooltipValue}>
        <Button
          variant="contained"
          startIcon={<CreateNewFolderIcon />}
          onClick={handleClickOpen}
          aria-label="add-folder"
          color="secondary"
        >
          New folder
        </Button>
      </Tooltip>
      {error && <ErrorDisplay error={error} /> /* TODO: Move (how to handele inline errors?) */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">
          Add new folder under
          <em>{folderName}</em>
        </DialogTitle>
        <DialogContent>
          <FormlyForm
            config={config}
            onSubmit={handleSubmit}
            onCancel={handleClose}
            submitting={loading}
            modal
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
