import ModuleContext from 'components/Modules/ModuleContext';
import SiteContext from 'components/Sites/SiteContext';
import { getApiBaseUrl } from 'environment-utils';
import { useContext, useState } from 'react';
import { Module } from 'tillr-graphql';
import UserProfileContext from 'UserProfileContext';

function textToCsv(text: string): string[][] {
  const csvData: string[][] = [];
  if (!text.length) {
    return csvData;
  }

  // Match groups:         1: Delimiter      2: Quoted field      3: Unquoted field
  const csvFieldsRegExp = /(,|\r?\n|\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^,\r\n]*))/g;

  let rowData: string[] = [];
  let matches = null;
  // eslint-disable-next-line no-cond-assign
  while ((matches = csvFieldsRegExp.exec(text))) {
    const [, delimiter, quotedField, unquotedField] = matches;

    if (delimiter !== ',' && delimiter !== '') {
      // Not a field delimiter, nor the very start of the string, so must be end of row
      csvData.push(rowData);
      rowData = [];
    }

    const matchedField = quotedField ? quotedField.replace(/""/g, '"') : unquotedField;
    rowData.push(matchedField);
  }

  if (rowData.length && rowData[0].length) {
    // Check for a final incomplete row, depending on how the CSV data was terminated
    csvData.push(rowData);
  }
  return csvData;
}

function tryParseJson(text: string) {
  try {
    return JSON.parse(text);
  } catch {
    return null;
  }
}

interface IRunReportState {
  loading: boolean;
  data: string[][] | null;
  error: string | null;
}

export enum ReportIdType {
  FormTemplate = 'formTemplateId',
  Report = 'reportId',
}

export default function useRunReport(): [
  (id: string, type: ReportIdType, inputParameters: Record<string, string>) => void,
  IRunReportState,
] {
  const { module } = useContext(ModuleContext)!;
  const moduleId = Object.values(Module).indexOf(module);
  const { siteId } = useContext(SiteContext)!;
  const userProfile = useContext(UserProfileContext);

  const [state, setState] = useState<IRunReportState>({ loading: false, data: null, error: null });
  const { loading, data } = state;

  const runReport = (id: string, type: ReportIdType, inputParameters: Record<string, string>) => {
    if (!loading) {
      setState({ loading: true, data, error: null });

      const systemParamsQueryParams = new URLSearchParams();
      systemParamsQueryParams.append('siteId', siteId.toString());
      systemParamsQueryParams.append('moduleId', moduleId.toString());
      systemParamsQueryParams.append(type, id.toString());
      const systemParamsQuery = systemParamsQueryParams.toString();

      const inputParamsQuery = new URLSearchParams(inputParameters).toString();
      const url = `${getApiBaseUrl()}/reportrunner/?${systemParamsQuery}&${inputParamsQuery}`;
      const headers = { Authorization: userProfile?.authHeaderValue || '' };

      fetch(url, { headers })
        .then((response) => {
          response.text().then((text) => {
            if (response.ok) {
              const csvData = textToCsv(text);
              setState({ loading: false, data: csvData, error: null });
            } else {
              const error =
                tryParseJson(text)?.Message || text || response.statusText || 'An error occurred.';
              setState({ loading: false, data, error });
            }
          });
        })
        .catch((reason: any) => {
          setState({ loading: false, data, error: reason.toString() });
        });
    }
  };

  return [runReport, state];
}
