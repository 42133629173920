import Typography from '@material-ui/core/Typography';
import { useGetTenantReportWithUsers, useUpdateReportUsers } from 'apollo-hooks';
import ActivityIndicator from 'components/ActivityIndicator';
import DataTable from 'components/DataViews/DataTable';
import Detail from 'components/DataViews/Detail';
import ErrorDisplay from 'components/ErrorDisplay';
import MagicForm from 'components/MagicForm';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { UpdateReportUsersMutationVariables } from 'tillr-graphql';

export default function Report(props: RouteComponentProps<{ reportId: string }>) {
  const {
    match: {
      params: { reportId },
    },
  } = props;

  const queryState = useGetTenantReportWithUsers({ id: reportId });
  const [updateReportUsers, mutationState] = useUpdateReportUsers();

  type ModelType = { userIds: string[] };

  const handleSubmit = (model: ModelType) => {
    const variables: UpdateReportUsersMutationVariables = {
      reportId,
      userIds: model.userIds,
    };
    updateReportUsers({ variables });
  };

  let model: ModelType | null = null;
  if (queryState.data?.tenantReport) {
    model = {
      userIds: queryState.data.tenantReport.users?.map((x) => x.id) || [],
    };
  }

  return (
    <div>
      <Typography variant="h2" noWrap>
        Report
      </Typography>
      {queryState.loading && <ActivityIndicator />}
      {queryState.error && <ErrorDisplay error={queryState.error} />}
      {mutationState.error && <ErrorDisplay error={mutationState.error} />}
      {mutationState.data?.updateReportUsers && <p>Updated successfully.</p>}
      {queryState.data && (
        <>
          {queryState.data.tenantReport ? (
            <Detail data={queryState.data.tenantReport} />
          ) : (
            !queryState.error && (
              <p>
                <i>Not found</i>
              </p>
            )
          )}
          <h3>Assign to userIds</h3>
          {model && (
            <MagicForm model={model} onSubmit={handleSubmit} submitting={mutationState.loading} />
          )}
          <h3>All users</h3>
          {queryState.data.usersAdmin && <DataTable data={queryState.data.usersAdmin} />}
        </>
      )}
    </div>
  );
}
