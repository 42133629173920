import { Box, Typography } from '@material-ui/core';
import { ThemeProvider } from '@nivo/core';
import { BoxLegendSvg } from '@nivo/legends';
import { ResponsiveSunburst } from '@nivo/sunburst';
import ErrorDisplay from 'components/ErrorDisplay';
import { schemePaired } from 'd3-scale-chromatic';
import React from 'react';
import { convertDataValueToString, DataColumn, DataRow } from '../utils';

interface ISunburstData {
  id: string;
  value: number;
  children: ISunburstData[];
}

interface IProps {
  columns: DataColumn[];
  data: DataRow[];
  groupByColumnName: string;
  thenGroupByColumnName: string;
}

export default function SunburstView(props: IProps) {
  const { columns, data, groupByColumnName, thenGroupByColumnName } = props;

  const groupByColumnType = columns.find((x) => x.name === groupByColumnName)?.type;
  if (!groupByColumnType) {
    return <ErrorDisplay errorMessage={`[${groupByColumnName}] is not a valid column.`} />;
  }
  const thenGroupByColumnType = columns.find((x) => x.name === thenGroupByColumnName)?.type;
  if (!thenGroupByColumnType) {
    return <ErrorDisplay errorMessage={`[${thenGroupByColumnName}] is not a valid column.`} />;
  }

  const sunburstData: ISunburstData = {
    id: '',
    value: 0,
    children: [],
  };

  data.forEach((row) => {
    const key1 = convertDataValueToString(row[groupByColumnName], groupByColumnType);
    const key2 = convertDataValueToString(row[thenGroupByColumnName], thenGroupByColumnType);

    let child1 = sunburstData.children.find((x) => x.id === key1);
    if (!child1) {
      child1 = { id: key1.toString(), value: 0, children: [] };
      sunburstData.children.push(child1);
    }

    const child2Key = `${key1.toString()}-${key2.toString()}`;
    const child2 = child1.children.find((x) => x.id === child2Key);
    if (!child2) {
      child1.children.push({ id: child2Key, value: 1, children: [] });
    } else {
      child2.value! += 1;
    }
  });

  return (
    <ThemeProvider>
      <Box height={400} padding={3} position="relative">
        <Typography variant="h2">{`${groupByColumnName} - ${thenGroupByColumnName}`}</Typography>
        <Box position="absolute">
          {/* // itemHeight + itemSpacing + default padding + some extra */}
          <svg height={sunburstData.children?.length! * (14 + 4 + 2 + 12)}>
            <BoxLegendSvg
              symbolShape="square"
              symbolSize={12}
              anchor="top-left"
              padding={{ top: 20 }}
              itemWidth={120}
              itemHeight={14}
              itemsSpacing={4}
              // itemHeight + itemSpacing + default padding + some extra
              containerHeight={sunburstData.children?.length! * (14 + 4 + 2 + 12)}
              containerWidth={240}
              direction="column"
              data={sunburstData.children?.map((x, i) => ({
                id: x.id!,
                label: x.id!,
                color: schemePaired[i],
              }))}
            />
          </svg>
        </Box>
        {sunburstData.children.length > 0 ? (
          <ResponsiveSunburst
            // @ts-ignore
            enableArcLabels
            // eslint-disable-next-line consistent-return
            arcLabel={(o: any) => {
              if (o.depth === 1) {
                return o.value;
              }
            }}
            arcLabelsSkipAngle={10}
            data={sunburstData}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
            cornerRadius={0}
            borderWidth={1}
            borderColor="white"
            colors={{ scheme: 'paired' }}
            childColor={{ from: 'color', modifiers: [['brighter', 0.5]] }}
            animate
            motionConfig="gentle"
          />
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography>No data.</Typography>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
}
