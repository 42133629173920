import { Divider, Grid, Typography } from '@material-ui/core';
import AdminBreadcrumbs from 'components/Admin/AdminBreadcrumbs';
import AdminNavigation from 'components/Admin/AdminNavigation';
import TitlePrefix from 'components/Shared/TitlePrefix';
import React from 'react';

export default function FormTemplates() {
  // TODO: Reinstate (taken out while adding filtering/pagination to Form Templates)
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h2" noWrap display="block">
            <>
              <TitlePrefix color="primary">ADMIN</TitlePrefix>
              Forms
            </>
          </Typography>
          <AdminBreadcrumbs title="Forms" />
        </Grid>
        <Grid item xs={12} sm={4} />
      </Grid>
      <Divider className="divider" />

      <AdminNavigation path="/admin/form-templates" />
    </>
  );
}
