import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { sentenceCase } from 'change-case';
import AssigneePickList from 'components/Assignees/AssigneePickList';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FilterOperation } from 'tillr-graphql';
import UserProfileContext from 'UserProfileContext';
import { FRIENDLY_DATE_FORMAT } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
      minWidth: '200px',
    },
    filter: {
      paddingBottom: theme.spacing(2),
    },
    select: {
      width: '100%',
    },
    popover: {
      minWidth: '300px',
    },
  }),
);

export enum FilterType {
  Assignee,
  Boolean,
  Date,
  String,
}

const filterTypeOperationMappings = new Map([
  [FilterType.Assignee, [FilterOperation.Equals]],
  [FilterType.Boolean, [FilterOperation.Equals]],
  [FilterType.Date, [FilterOperation.GreaterThan, FilterOperation.LessThan]],
  [
    FilterType.String,
    [FilterOperation.Contains, FilterOperation.StartsWith, FilterOperation.Equals],
  ],
]);

export interface IFilter<TFilterBy> {
  filterBy: TFilterBy;
  operation: FilterOperation;
  argument: string;
}

interface IProps<TFilterBy> {
  filterByValues: TFilterBy[];
  filterTypeMappings: Map<TFilterBy, FilterType>;
  onChange: (filter: IFilter<TFilterBy>) => void;
}

export default function FilterControl<TFilterBy extends string>(props: IProps<TFilterBy>) {
  const classes = useStyles();
  const { filterByValues, filterTypeMappings, onChange } = props;

  const userProfile = useContext(UserProfileContext)!;

  const isInitialRender = useRef(true);

  const [filterBy, setFilterBy] = useState<TFilterBy>(filterByValues[0]);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [operation, setOperation] = useState(FilterOperation.Contains);
  const [argument, setArgument] = useState('');

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleChangeFilterBy = (event: React.ChangeEvent<{ value: unknown }>) => {
    const nextFilterBy = event.target.value as TFilterBy;
    setFilterBy(nextFilterBy);

    const nextFilterType = filterTypeMappings.get(nextFilterBy)!;
    const nextOperation = filterTypeOperationMappings.get(nextFilterType)![0];
    setOperation(nextOperation);

    // Reset argument whenever filter is changed
    setArgument('');

    if (nextFilterType === FilterType.String && argument) {
      onChange({ filterBy: nextFilterBy, operation: nextOperation, argument });
    }
  };

  const handleChangeOperation = (event: React.ChangeEvent<{ value: unknown }>) => {
    const nextOperation = event.target.value as FilterOperation;
    setOperation(nextOperation);

    if (argument) {
      onChange({ filterBy, operation: nextOperation, argument });
    }
  };

  const filterType = filterTypeMappings.get(filterBy);

  const checkPermissions = (_filterBy: TFilterBy): boolean => {
    switch (filterTypeMappings.get(_filterBy)) {
      case FilterType.Assignee: {
        return userProfile.hasAnyPermission(['Users.View']);
      }
      default:
        return true;
    }
  };

  const handleChangeAssigneeArgument = (assigneeId: string) => {
    setArgument(assigneeId);
    onChange({ filterBy, operation, argument: assigneeId });
  };

  const handleChangeBooleanArgument = (event: React.ChangeEvent<{ value: unknown }>) => {
    const nextArgument = event.target.value as string;
    setArgument(nextArgument);
    onChange({ filterBy, operation, argument: nextArgument });
  };

  const handleChangeDateArgument = (date: Date | null) => {
    if (date == null) {
      setArgument('');
    } else {
      const nextArgument = date.toISOString();
      setArgument(nextArgument);
      onChange({ filterBy, operation, argument: nextArgument });
    }
  };

  const handleChangeStringArgument = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextArgument = event.target.value;
    setArgument(nextArgument);
    // Do not trigger onUpdate yet, wait until user has stopped typing (see useEffect timer below)
  };
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return () => null;
    }
    if (filterType === FilterType.String) {
      // Wait until user has stopped typing (500ms delay)
      const timer = setTimeout(() => onChange({ filterBy, operation, argument }), 500);
      return () => clearTimeout(timer);
    }
    return () => null;
    // eslint-disable-next-line
  }, [argument]);

  const getHumanFriendlyLabel = (value: string) => {
    const getKeyValue =
      <U extends keyof T, T extends object>(key: U) =>
      (obj: T) =>
        obj[key];
    interface ILabels {
      [key: string]: string;
    }

    const labels: ILabels = {
      GREATER_THAN: 'After',
      LESS_THAN: 'Before',
    };

    return getKeyValue<keyof ILabels, ILabels>(value)(labels) || sentenceCase(value);
  };

  return (
    <>
      <Tooltip title="Filter results">
        <IconButton
          color="inherit"
          aria-label="filter results"
          aria-haspopup="true"
          aria-controls="filter-results"
          onClick={handleFilterClick}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Popover
        className={classes.popover}
        id="filter-results"
        anchorEl={filterAnchorEl}
        keepMounted
        open={Boolean(filterAnchorEl)}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid className={classes.wrapper}>
          <Grid item className={classes.filter}>
            <FormControl className={classes.select}>
              <InputLabel id="filter-controls-by-label">Filter by column</InputLabel>
              <Select
                labelId="filter-controls-by-label"
                id="filter-controls-by"
                value={filterBy}
                onChange={handleChangeFilterBy}
              >
                {filterByValues.filter(checkPermissions).map((x) => (
                  <MenuItem key={x} value={x}>
                    {getHumanFriendlyLabel(x)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item className={classes.filter}>
            <FormControl className={classes.select}>
              <InputLabel id="filter-controls-type-label">Filter type</InputLabel>
              <Select
                labelId="filter-controls-type-label"
                id="filter-controls-type"
                value={operation}
                onChange={handleChangeOperation}
              >
                {filterTypeOperationMappings.get(filterTypeMappings.get(filterBy)!)!.map((x) => (
                  <MenuItem key={x} value={x}>
                    {getHumanFriendlyLabel(x)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item className={classes.filter}>
            <FormControl className={classes.select}>
              {filterType === FilterType.Boolean && (
                <>
                  <InputLabel id="filter-controls-boolean-label">true or false</InputLabel>
                  <Select
                    labelId="filter-controls-boolean-label"
                    value={argument}
                    onChange={handleChangeBooleanArgument}
                  >
                    {['true', 'false'].map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              {filterType === FilterType.Date && (
                // REVIEW: if this is desired for all DatePickers, declare it once at the App level
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    label="Select date"
                    format={FRIENDLY_DATE_FORMAT}
                    value={argument ? Date.parse(argument) : null}
                    onChange={handleChangeDateArgument}
                    animateYearScrolling
                  />
                </MuiPickersUtilsProvider>
              )}
              {filterType === FilterType.String && (
                <TextField
                  label="Filter on"
                  onChange={handleChangeStringArgument}
                  value={argument}
                />
              )}
              {filterType === FilterType.Assignee && (
                <AssigneePickList onChange={handleChangeAssigneeArgument} value={argument} />
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
