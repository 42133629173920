import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { UiDataProvider } from 'UiDataProvider';
import UserProfileContext from 'UserProfileContext';
import { setStartUpRoute } from 'utils';

interface IProps {
  permissions?: string[];
  'require-all'?: boolean;
}

export default function AuthenticatedRoute(props: IProps & RouteProps) {
  const userProfile = useContext(UserProfileContext);

  const { permissions, 'require-all': all = false, ...routeProps } = props;

  if (userProfile && (!permissions || userProfile.hasPermission(permissions, all))) {
    return (
      <UiDataProvider>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Route {...routeProps} />
      </UiDataProvider>
    );
  }

  if (!userProfile) {
    setStartUpRoute(window.location.pathname);
    return <Redirect to="/welcome" />;
  }

  return <Redirect to="/unauthorised" />;
}
