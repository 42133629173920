import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useGetUserAdmin, useUpdateUser } from 'apollo-hooks';
import AdminBreadcrumbs from 'components/Admin/AdminBreadcrumbs';
import AdminNavigation from 'components/Admin/AdminNavigation';
import SiteMultiCheckboxes from 'components/Admin/Sites/SiteMultiCheckboxes';
import ErrorDisplay from 'components/ErrorDisplay';
import ActionButtons from 'components/Shared/ActionButtons';
import CancelButton from 'components/Shared/CancelButton';
import FormsButtons from 'components/Shared/FormsButtons';
import HorizontalLine from 'components/Shared/HorizontalLine';
import LinkButton from 'components/Shared/LinkButton';
import MandatoryFieldMessage from 'components/Shared/MandatoryFieldMessage';
import ProgressBar from 'components/Shared/ProgressBar';
import ProgressButton from 'components/Shared/ProgressButton';
import TitlePrefix from 'components/Shared/TitlePrefix';
import React, { useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { UpdateUserInputType, UpdateUserMutationVariables } from 'tillr-graphql';
import RolePickList from './RolePickList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navMargin: {
      margin: theme.spacing(2, 0),
    },
  }),
);

interface IUserModel {
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: string;
}

export default function EditUser(props: RouteComponentProps<{ userId: string }>) {
  const history = useHistory();
  const {
    match: {
      params: { userId },
    },
  } = props;
  const { loading, error, data } = useGetUserAdmin({ id: userId });
  const [updateUser, mutationState] = useUpdateUser({ id: userId });
  const [roleId, setRoleId] = useState('');
  const [siteIds, setSiteIds] = useState<number[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [model, setModel] = useState<IUserModel>({});
  const classes = useStyles();

  useEffect(() => {
    if (data?.userAdmin) {
      setRoleId(data.userAdmin.role.id);
      setSiteIds(data.userAdmin.sites.map((x) => x.id));
    }
  }, [data]);

  useEffect(() => {
    const user = {
      firstName: data?.userAdmin?.firstName,
      lastName: data?.userAdmin?.lastName,
      email: data?.userAdmin?.email,
      role: data?.userAdmin?.role.name,
    };
    setModel(user);
  }, [data, setModel]);

  function getUserName() {
    let user = 'User';
    if (data && data.userAdmin) {
      user = `${data.userAdmin.firstName} ${data.userAdmin.lastName}`;
    }
    return user;
  }

  if (mutationState.data?.updateUser) {
    return <Redirect to={`/admin/users/${userId}`} />;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const user: UpdateUserInputType = {
      userId: data!.userAdmin!.id,
      // TODO: Make modules editable
      modules: data!.userAdmin!.modules,
      roleId,
      siteIds,
    };
    const variables: UpdateUserMutationVariables = { user };
    updateUser({ variables });
  };

  const onCancel = () => {
    const route = '/admin/users';
    history.push(route);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h2" noWrap display="block">
            <>
              <TitlePrefix color="primary">ADMIN</TitlePrefix>
              {getUserName()}
            </>
          </Typography>
          <AdminBreadcrumbs subtitle="Users" sublink="users" title={getUserName()} />
        </Grid>
        <Grid item xs={12} sm={4} />
      </Grid>
      <HorizontalLine margin={[2, 0, 2, 0]} />

      <AdminNavigation path="/admin/users" />

      <div className={classes.navMargin}>
        <ActionButtons>
          <LinkButton
            to={`/admin/users/${userId}`}
            startIcon={<ArrowBackIcon />}
            aria-label="back"
            tooltip="Return to user"
          >
            Back
          </LinkButton>
        </ActionButtons>
      </div>

      {loading && <ProgressBar />}
      {error && <ErrorDisplay error={error} />}
      {mutationState.error && <ErrorDisplay error={mutationState.error} />}
      {data?.userAdmin && (
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <fieldset>
                <MandatoryFieldMessage />
                <Grid container spacing={3}>
                  <Grid item xs={12} className="tillr-form-grid-element">
                    <RolePickList roleId={roleId} onChange={setRoleId} required />
                  </Grid>
                  <Grid item xs={12}>
                    <HorizontalLine margin={[0, 0, 1, 0]} />
                    <Typography variant="h6">
                      Invited user will have access to the following sites:
                    </Typography>
                  </Grid>
                </Grid>
                <SiteMultiCheckboxes readonly={false} siteIds={siteIds} onChange={setSiteIds} />
                <FormsButtons>
                  <CancelButton label="Cancel" loading={mutationState.loading} onClick={onCancel} />
                  <ProgressButton label="Save" loading={mutationState.loading} type="submit" />
                </FormsButtons>
              </fieldset>
            </form>
          </CardContent>
        </Card>
      )}
    </>
  );
}
