import { useGetBuildNumber } from 'apollo-hooks';
import buildNumber from 'build-number';
import React from 'react';

export default function BuildNumbersDisplay() {
  const { loading, error, data } = useGetBuildNumber();
  // eslint-disable-next-line no-nested-ternary
  const apiBuildNumber = loading ? 'loading...' : error ? 'error' : data?.buildNumber;
  return (
    <>
      <p>
        Build numbers: UI
        {` ${buildNumber}`}, API
        {` ${apiBuildNumber}`}
      </p>
    </>
  );
}
