import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useDeleteAttachment } from 'apollo-hooks';
import ActivityIndicator from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import ModuleContext from 'components/Modules/ModuleContext';
import SiteContext from 'components/Sites/SiteContext';
import React, { useContext } from 'react';
import { DeleteAttachmentMutationVariables, ParentType } from 'tillr-graphql';
import { IAttachment } from './IAttachment';
import makeAttachmentStyles from './makeAttachmentStyles';

interface IProps {
  parentId: string;
  parentType: ParentType;
  attachment: IAttachment;
  onDelete: (attachment: IAttachment) => void;
}

export default function DeleteAttachmentControl(props: IProps) {
  const { parentId, parentType, attachment, onDelete } = props;

  const classes = makeAttachmentStyles();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const deleteAttachmentProps = {
    siteId,
    module,
    parentId,
    parentType,
  };
  const [deleteAttachment, { loading, error }] = useDeleteAttachment(deleteAttachmentProps);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSubmit = () => {
    const variables: DeleteAttachmentMutationVariables = { module, id: attachment.id };
    deleteAttachment({ variables }).then(() => {
      // TODO: If result.data.deleteAttachment is not defined, do
      // we not want to still remove it's reference from the form?
      // if (result?.data?.deleteAttachment) {
      onDelete(attachment);
      // }
      // No need to close since whole control will be deleted anyway
    });
  };

  return (
    <>
      <Button className={classes.attachmentLinks} onClick={handleOpenDialog} disabled={loading}>
        Delete
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete attachment</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to delete "${attachment.name}"?`}
          </DialogContentText>
          {loading && <ActivityIndicator />}
          {error && <ErrorDisplay error={error} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus disabled={loading}>
            {!error ? 'Confirm' : 'Retry'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
