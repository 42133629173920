import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AttachmentIcon from '@material-ui/icons/Attachment';
import EditIcon from '@material-ui/icons/Edit';
import NoteIcon from '@material-ui/icons/Note';
import { useGetMyFile } from 'apollo-hooks';
import ActivityIndicator from 'components/ActivityIndicator';
import AddAttachmentsControl from 'components/Attachments/AddAttachmentsControl';
import ManageAttachmentsControl from 'components/Attachments/ManageAttachmentsControl';
import ErrorDisplay from 'components/ErrorDisplay';
import ModuleContext from 'components/Modules/ModuleContext';
import ModuleLinkButton from 'components/Modules/ModuleLinkButton';
import ModuleListItem from 'components/Modules/ModuleListItem';
import ActionButtons from 'components/Shared/ActionButtons';
import HorizontalLine from 'components/Shared/HorizontalLine';
import SiteContext from 'components/Sites/SiteContext';
import FormlyForm from 'formly/FormlyForm';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { ParentType } from 'tillr-graphql';
import FileBreadcrumbs from './FileBreadcrumbs';
import { buildFormlyConfig, buildModel } from './utils';

export default function File(props: RouteComponentProps<{ fileId: string }>) {
  const [anchorNotesEl, setAnchorNotesEl] = React.useState<null | HTMLElement>(null);
  const [anchorAttachmentsEl, setAnchorAttachmentsEl] = React.useState<null | HTMLElement>(null);
  const {
    match: {
      params: { fileId },
    },
  } = props;

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const { loading, error, data } = useGetMyFile({ siteId, module, id: fileId });

  const handleNotesMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorNotesEl(event.currentTarget);
  };
  const handleNotesMenuClose = () => {
    setAnchorNotesEl(null);
  };
  const handleAttachmentsMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorAttachmentsEl(event.currentTarget);
  };
  const handleAttachmentsMenuClose = () => {
    setAnchorAttachmentsEl(null);
  };

  return (
    <div>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data &&
        (data.myFile ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h2" noWrap display="block">
                  {data.myFile.name}
                </Typography>
                <FileBreadcrumbs fileTypeId={data.myFile.fileTypeId} title={data.myFile.name} />
              </Grid>
            </Grid>
            <HorizontalLine margin={[2, 0, 2, 0]} />
            <ActionButtons>
              {/*  */}
              <Tooltip title="Add or view attachments">
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<AttachmentIcon />}
                  aria-haspopup="true"
                  aria-controls="attachments-menu"
                  onClick={handleAttachmentsMenuClick}
                >
                  {data.myFile.attachments?.length ? (
                    <Badge badgeContent={data.myFile.attachments.length} color="secondary">
                      Attachments
                    </Badge>
                  ) : (
                    <>Attachments</>
                  )}
                </Button>
              </Tooltip>
              <Popover
                id="attachments-menu"
                anchorEl={anchorAttachmentsEl}
                keepMounted
                open={Boolean(anchorAttachmentsEl)}
                onClose={handleAttachmentsMenuClose}
              >
                <List>
                  <ListItem>
                    <AddAttachmentsControl
                      // Use key to destroy & recreate component on change
                      key={data.myFile.attachments?.length}
                      parentId={fileId}
                      parentType={ParentType.File}
                      onOpenDialog={handleAttachmentsMenuClose}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="View attachments" />
                  </ListItem>
                </List>
              </Popover>
              <Tooltip title="Add or view notes">
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<NoteIcon />}
                  aria-haspopup="true"
                  aria-controls="notes-menu"
                  onClick={handleNotesMenuClick}
                >
                  {data.myFile.fileNotes?.length ? (
                    <Badge badgeContent={data.myFile.fileNotes.length} color="secondary">
                      Notes
                    </Badge>
                  ) : (
                    <>Notes</>
                  )}
                </Button>
              </Tooltip>
              <Popover
                id="notes-menu"
                anchorEl={anchorNotesEl}
                keepMounted
                open={Boolean(anchorNotesEl)}
                onClose={handleNotesMenuClose}
              >
                <List>
                  <ModuleListItem
                    to={`/files/${fileId}/notes/add`}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    aria-label="Add note"
                  >
                    Add note
                  </ModuleListItem>
                  <ListItem>
                    <ListItemText primary="View notes" />
                  </ListItem>
                </List>
              </Popover>
              {/*  */}
              <ModuleLinkButton
                to={`/files/${fileId}/edit`}
                aria-label="Edit file"
                tooltip="Edit file"
                startIcon={<EditIcon />}
              >
                Edit
              </ModuleLinkButton>
            </ActionButtons>
            <FormlyForm
              formId={data.myFile.form.id}
              mode="readonly"
              config={buildFormlyConfig(data.myFile.form.template)}
              model={buildModel(data.myFile.name, data.myFile.form.formDataJson)}
            />
            {data.myFile.attachments && (
              <>
                <HorizontalLine margin={[4, 0, 4, 0]} />
                <Card>
                  <CardContent>
                    <Typography variant="h6">
                      <AttachmentIcon />
                      Attachments
                    </Typography>
                    <ManageAttachmentsControl
                      parentId={fileId}
                      parentType={ParentType.File}
                      attachments={data.myFile.attachments.slice()}
                      enableCreate
                    />
                  </CardContent>
                </Card>
              </>
            )}
            {data.myFile.fileNotes && (
              <>
                <HorizontalLine margin={[4, 0, 4, 0]} />
                <Card>
                  <CardContent>
                    <Typography variant="h6">
                      <NoteIcon />
                      Notes
                    </Typography>
                  </CardContent>
                </Card>
              </>
            )}
          </>
        ) : (
          !error && (
            <p>
              <i>Not found</i>
            </p>
          )
        ))}
    </div>
  );
}
