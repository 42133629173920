import { Card, CardContent, Divider, Grid, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useGetForm, useUpdateForm } from 'apollo-hooks';
import ErrorDisplay from 'components/ErrorDisplay';
import ModuleContext from 'components/Modules/ModuleContext';
import ModuleLinkButton from 'components/Modules/ModuleLinkButton';
import ModuleRedirect from 'components/Modules/ModuleRedirect';
import ActionButtons from 'components/Shared/ActionButtons';
import HorizontalLine from 'components/Shared/HorizontalLine';
import PreventTransition from 'components/Shared/PreventTransition';
import ProgressBar from 'components/Shared/ProgressBar';
import TitlePrefix from 'components/Shared/TitlePrefix';
import SiteContext from 'components/Sites/SiteContext';
import HelpText from 'formly/components/shared/HelpText';
import FormlyForm from 'formly/FormlyForm';
import { IModel } from 'formly/IModel';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { UpdateFormInputType, UpdateFormMutationVariables } from 'tillr-graphql';
import FormBreadcrumbs from './FormBreadcrumbs';

export default function EditForm(props: RouteComponentProps<{ formId: string }>) {
  const {
    match: {
      params: { formId },
    },
  } = props;
  const history = useHistory();
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const queryState = useGetForm({ siteId, module, id: formId });
  const [updateForm, mutationState] = useUpdateForm({ siteId, module, id: formId });
  const [instanceName, setInstanceName] = useState('');
  const [existingInstanceName, setExistingInstanceName] = useState('');
  const [nameViaForm, setNameViaForm] = useState('');

  useEffect(() => {
    if (queryState.data?.form?.name) {
      setInstanceName(queryState.data.form.name);
      setExistingInstanceName(queryState.data.form.name);
    }
  }, [queryState, setInstanceName, setExistingInstanceName]);

  if (mutationState.data?.updateForm) {
    return <ModuleRedirect to={`/forms/${formId}`} />;
  }

  // TODO: Review - This was NOT how it was done originally
  // but there is a package on UAT (and perhaps prod) that
  // is stopping the original, better way from working
  const customFunction = (identifier: string, value: string) => {
    switch (identifier) {
      default: // editNameViaForm
        if (value.length) {
          const newName = ` - ${value}`;
          const typedName = instanceName.replace(nameViaForm, '');
          setInstanceName(`${typedName} - ${value}`);
          setNameViaForm(newName);
        }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstanceName(event.currentTarget.value);
  };

  const handleBlur = (event: any) => {
    if (!event.currentTarget.value.length) {
      setInstanceName(existingInstanceName);
    }
  };

  const handleSubmit = (model: IModel) => {
    let modelProp = model;
    let createTasksProp = false;

    if (typeof model.model !== 'undefined' && typeof model.createTasks !== 'undefined') {
      modelProp = model.model;
      createTasksProp = model.createTasks;
    }

    const form: UpdateFormInputType = {
      id: formId,
      // TODO: make name editable?
      name: instanceName,
      formDataJson: JSON.stringify(modelProp),
      isSubmitted: false,
    };

    const variables: UpdateFormMutationVariables = {
      siteId,
      module,
      form,
      createTasks: createTasksProp,
    };
    updateForm({ variables });
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <>
      {queryState.loading && <ProgressBar />}
      {queryState.error && <ErrorDisplay error={queryState.error} />}
      {mutationState.error && <ErrorDisplay error={mutationState.error} />}
      {queryState.data?.form && (
        <>
          <PreventTransition active />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h2" display="block">
                <>
                  <TitlePrefix color="secondary">EDIT</TitlePrefix>
                  {instanceName}
                </>
              </Typography>
              {/* TODO: Replace 'Form Instances' with the Form Template Name */}
              <FormBreadcrumbs
                title={instanceName}
                subtitle="Form Instances"
                sublink={`${queryState.data.form.formTemplate.activeFormTemplateId}/forms`}
              />
            </Grid>
          </Grid>
          <HorizontalLine margin={[2, 0, 2, 0]} />
          <ActionButtons>
            <ModuleLinkButton
              to={`/forms/${formId}`}
              aria-label="cancel"
              startIcon={<CloseIcon />}
              tooltip="Cancel"
            >
              Cancel
            </ModuleLinkButton>
          </ActionButtons>

          <Card>
            <CardContent>
              <form noValidate autoComplete="off">
                <fieldset className="formly-form-container">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} className="tillr-form-grid-element">
                      <Typography variant="h5" gutterBottom>
                        Form name
                      </Typography>
                      <TextField
                        id="name"
                        value={instanceName}
                        variant="outlined"
                        onChange={handleChange}
                        required
                        fullWidth
                        onBlur={handleBlur}
                      />
                      <HelpText value="Edit this value to give your form a more identifiable name" />
                    </Grid>
                  </Grid>
                </fieldset>
              </form>
            </CardContent>
          </Card>

          <Divider className="divider" />

          <FormlyForm
            formId={queryState.data.form.id}
            customFunctions={[customFunction]}
            mode="manage"
            config={JSON.parse(queryState.data.form.template)}
            model={JSON.parse(queryState.data.form.formDataJson)}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            submitting={mutationState.loading}
            fixedButtons
          />
        </>
      )}
    </>
  );
}
