import { Button, Tooltip } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ListIcon from '@material-ui/icons/List';
import { useSiteModuleRoute } from 'components/route-utils';
import React from 'react';
import { Link } from 'react-router-dom';

export default function SwitchViewControl(props: { currentView: 'calendar' | 'list' }) {
  const { currentView } = props;

  return (
    <ButtonGroup size="small" color="secondary" aria-label="outlined primary button group">
      <Tooltip title="View Tasks in list view">
        <Button
          className="no-wrap"
          variant="contained"
          color="default"
          component={Link}
          to={useSiteModuleRoute('/tasks?view=list')}
          aria-label="View Tasks in list view"
          startIcon={<ListIcon />}
          disabled={currentView === 'list'}
        >
          List view
        </Button>
      </Tooltip>
      <Tooltip title="View Tasks in calendar view">
        <Button
          className="no-wrap"
          variant="contained"
          color="default"
          component={Link}
          to={useSiteModuleRoute('/tasks?view=calendar')}
          aria-label="View Tasks in calendar view"
          startIcon={<DateRangeIcon />}
          disabled={currentView === 'calendar'}
        >
          Calendar view
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
}

