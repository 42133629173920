import { TextField, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { useUpdateAttachment } from 'apollo-hooks';
import ActivityIndicator from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import ModuleContext from 'components/Modules/ModuleContext';
import React, { useContext, useEffect, useState } from 'react';
import { UpdateAttachmentMutationVariables } from 'tillr-graphql';
import { IAttachment } from './IAttachment';
import makeAttachmentStyles from './makeAttachmentStyles';

const CssTextField = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInputBase-input': {
      fontWeight: 'bold',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'black',
    },
  },
})(TextField);

interface IProps {
  attachment: IAttachment;
  onUpdate: (attachment: IAttachment) => void;
}

export default function EditAttachmentControl(props: IProps) {
  const classes = makeAttachmentStyles();
  const { attachment, onUpdate } = props;

  const { module } = useContext(ModuleContext)!;
  const [updateAttachment, { loading, error }] = useUpdateAttachment({ module, id: attachment.id });
  const [name, setName] = useState(attachment.name);
  const [mode, setMode] = useState(false);
  const editableField = React.createRef<HTMLInputElement>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };

  const handleSubmit = () => {
    if (name !== attachment.name) {
      const variables: UpdateAttachmentMutationVariables = { module, id: attachment.id, name };
      updateAttachment({ variables }).then((result) => {
        if (result?.data?.updateAttachment) {
          if (result.data.updateAttachment.name !== name) {
            // Just in case server has done any trimming or formatting
            setName(result.data.updateAttachment.name);
          }
          onUpdate({ ...attachment, name: result.data.updateAttachment.name });
          setMode(false);
        }
      });
    } else {
      setMode(false);
    }
  };

  const handleCancel = () => {
    setName(attachment.name);
    setMode(false);
  };

  const handleEdit = () => {
    setMode(!mode);
  };

  useEffect(() => {
    if (mode) {
      if (editableField && editableField.current) {
        editableField.current.focus();
      }
    }
  }, [mode, editableField]);

  return (
    <>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      <div>
        <CssTextField
          value={name}
          onChange={handleChange}
          disabled={!mode}
          inputRef={editableField}
        />
      </div>
      {/* {createdDate && (
        <span>
          Added
          {` ${createdDate} `}
          at
          {` ${createdTime} `}
        </span>
      )}
      {createdByUser && (
        <span>
          by
          {` ${createdByUser} `}
        </span>
      )} */}
      {!mode ? (
        <Tooltip title="Edit file name">
          <Button className={classes.attachmentLinks} onClick={handleEdit}>
            <span>Edit</span>
          </Button>
        </Tooltip>
      ) : (
        <>
          <Tooltip title="Save changes">
            <Button className={classes.attachmentLinks} onClick={handleSubmit}>
              <span>Save</span>
            </Button>
          </Tooltip>
          <Tooltip title="Cancel">
            <Button className={classes.attachmentLinks} onClick={handleCancel}>
              <span>Cancel</span>
            </Button>
          </Tooltip>
        </>
      )}
    </>
  );
}
