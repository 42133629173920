import ActivityIndicator from 'components/ActivityIndicator';
import React, { lazy, Suspense } from 'react';

const Home = lazy(() => import(/* webpackChunkName: "reports" */ './Home'));

export default function WebpackChunkWrapper() {
  return (
    <Suspense fallback={<ActivityIndicator />}>
      <Home />
    </Suspense>
  );
}
