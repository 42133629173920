import { getModuleFromRouteParam, getSiteModuleRoute } from 'components/route-utils';
import SiteContext from 'components/Sites/SiteContext';
import React, { PropsWithChildren, useContext, useState } from 'react';
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { Module } from 'tillr-graphql';
import UserProfileContext from 'UserProfileContext';
import ModuleContext from './ModuleContext';

export default function ModuleManager(props: PropsWithChildren<{}>) {
  const { children } = props;

  const { siteId } = useContext(SiteContext)!;
  const userProfile = useContext(UserProfileContext);
  const [currentModule, setModule] = useState<Module | null>(null);

  const routeMatch = useRouteMatch<{ module: string; 1: string }>();
  const {
    params: { module: moduleRouteParam, 1: path },
  } = routeMatch;
  const queryString = useLocation().search;

  const moduleFromRoute = getModuleFromRouteParam(moduleRouteParam);

  if (userProfile && currentModule === null) {
    // User logged in
    setModule(moduleFromRoute || userProfile.modules[0]);
  } else if (!userProfile && currentModule !== null) {
    // User logged out
    setModule(null);
  }

  if (moduleFromRoute && currentModule && moduleFromRoute !== currentModule) {
    // User is on a module route, and module has changed
    return <Redirect to={getSiteModuleRoute(siteId, currentModule, path + queryString)} />;
  }

  return (
    <ModuleContext.Provider value={{ module: currentModule || Module.TillrPlatform, setModule }}>
      {children}
    </ModuleContext.Provider>
  );
}
