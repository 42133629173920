import { Box, Button, Chip, Grid, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ActivityIndicator from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import useRunReportToJson from 'components/Reports/useRunReportToJson';
import SiteContext from 'components/Sites/SiteContext';
import { schemePaired } from 'd3-scale-chromatic';
import { addDays, addMonths, isSameDay, set } from 'date-fns';
import format from 'date-fns/format';
import React, { useContext, useEffect, useState } from 'react';
import { IAppTheme } from 'themes/useGetTheme';
import { groupByProperty } from 'utils';

const useStyles = makeStyles((theme: IAppTheme) => ({
  dashboardText: {
    '& h2': {
      fontSize: 22,
      margin: theme.spacing(0, 0, 1, 0),
      padding: theme.spacing(0, 0, 1, 0),
      borderBottom: 'solid 1px #ccc',
      color: '#111',
    },
    '& p': {
      margin: theme.spacing(0, 0, 2, 0),
      fontSize: 12,
    },
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  monthButton: {
    textTransform: 'unset',
    whiteSpace: 'nowrap',
  },
  reported: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
    '& .date': {
      fontWeight: 'bold',
    },
    '& .count': {
      marginLeft: '5px',
      fontSize: 22,
      color: schemePaired[5],
    },
  },
}));

interface IProps {
  label: string;
  reportId: string;
  dateColumn: string;
  detailColumn: string;
  dayColor?: string;
  groupByColumn?: string;
  groupByColors?: {
    [value: string]: string;
  };
}

export default function CalendarReport(props: IProps) {
  const {
    label,
    reportId,
    dateColumn,
    detailColumn,
    dayColor = schemePaired[7],
    groupByColumn,
    groupByColors,
  } = props;

  const { siteId } = useContext(SiteContext)!;

  const classes = useStyles();
  let daysDisplayed = 0;

  const today = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  const [month, setMonth] = useState(today);
  const startDate = set(new Date(), {
    year: month.getFullYear(),
    month: month.getMonth(),
    date: 1,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const startColumn = Number(format(startDate, 'i')) - 1;
  const [runReport, { loading, data, error }] = useRunReportToJson(reportId);

  useEffect(() => {
    runReport({
      '@p_From_date': format(startDate, 'dd MMM yyyy'),
      '@p_To_date': format(addMonths(startDate, 1), 'dd MMM yyyy'),
    });
    // eslint-disable-next-line
  }, [siteId, month]);

  function getItemsForDay(date: Date): JSX.Element | null {
    if (!data) {
      return null;
    }
    const items = data.filter((item) => isSameDay(date, new Date(item[dateColumn])));

    if (items.length === 0) {
      return null;
    }

    const defaultItemColor = schemePaired[5];
    const groupedByColor: Array<{
      value?: string;
      color: string;
      itemsGroup: Record<string, any>[];
    }> = groupByColumn
      ? Object.entries(groupByProperty(items, (item) => item[groupByColumn])).map(
          ([value, itemsGroup]) => ({
            value,
            color: groupByColors?.[value] ?? defaultItemColor,
            itemsGroup,
          }),
        )
      : [{ color: defaultItemColor, itemsGroup: items }];

    return (
      <Grid container spacing={1} justify="flex-end" style={{ marginTop: 5 }}>
        {groupedByColor.map(({ value, color, itemsGroup }, groupIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item key={groupIndex}>
            <Tooltip
              title={
                <>
                  <div>
                    {`${format(date, 'iiii do LLLL yyyy')}:`}
                    {value && (
                      <>
                        <br />
                        {value}
                      </>
                    )}
                  </div>
                  {itemsGroup.map((item, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={i}>{`${item.Site}: ${item[detailColumn]}`}</div>
                  ))}
                </>
              }
            >
              <Chip style={{ backgroundColor: color }} label={itemsGroup.length} size="small" />
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    );
  }

  function Day({ index }: { index: number }): JSX.Element {
    if (index < startColumn) {
      return <div className="dashboard-calendar--blank" />;
    }
    const date = addDays(startDate, daysDisplayed);
    if (date.getMonth() !== startDate.getMonth()) {
      return <div className="dashboard-calendar--blank" />;
    }
    const day = format(date, 'dd');
    daysDisplayed += 1;

    const itemsForDay = getItemsForDay(date);

    return (
      <Tooltip title={itemsForDay ? '' : format(date, 'iiii do LLLL yyyy')}>
        <div style={{ backgroundColor: dayColor }}>
          <strong>{day}</strong>
          {itemsForDay}
        </div>
      </Tooltip>
    );
  }

  const renderChart = () => (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Button
          variant="text"
          className={classes.monthButton}
          onClick={() => setMonth((m) => addMonths(m, -1))}
        >
          &lt; {format(addMonths(month, -1), 'MMMM yyyy')}
        </Button>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Typography variant="body2" display="block" className={classes.reported}>
            <span className="date">{format(month, 'MMMM yyyy')}</span>
            {': '}
            <span className="count">{loading ? ' ' : data?.length ?? 0}</span>
          </Typography>
        )}
        <Button
          variant="text"
          className={classes.monthButton}
          onClick={() => setMonth((m) => addMonths(m, 1))}
        >
          {format(addMonths(month, 1), 'MMMM yyyy')} &gt;
        </Button>
      </Box>
      <div className="dashboard-calendar">
        {[...Array(42)].map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i}>
            <Day index={i} />
          </div>
        ))}
      </div>
    </>
  );

  return (
    <div>
      <div className={classes.dashboardText}>
        <Typography variant="h2" display="block">
          {`${label} report`}
        </Typography>
      </div>
      {error && <ErrorDisplay error={error} />}
      {!error && renderChart()}
    </div>
  );
}
