/* eslint-disable react/jsx-props-no-spreading */
import { useSiteModuleRoute } from 'components/route-utils';
import LinkButton from 'components/Shared/LinkButton';
import React from 'react';

interface IProps {
  to: string;
  'aria-label'?: string;
  startIcon?: React.ReactNode;
  tooltip?: string;
}

export default function ModuleLinkButton(props: React.PropsWithChildren<IProps>) {
  const { to, ...rest } = props;
  return <LinkButton to={useSiteModuleRoute(to.toString())} {...rest} />;
}
