import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useCreateSite } from 'apollo-hooks';
import ErrorDisplay from 'components/ErrorDisplay';
import FormlyForm from 'formly/FormlyForm';
import getStaticTemplate from 'formly/staticTemplates';
import React from 'react';
import { Redirect } from 'react-router';
import { CreateSiteInputType, CreateSiteMutationVariables } from 'tillr-graphql';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
      },
    },
  }),
);

interface IProps {
  parent: string;
  onCloseMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function AddSite(props: IProps) {
  const classes = useStyles();
  const { parent, onCloseMenu } = props;
  const siteId = Number(parent);
  const [open, setOpen] = React.useState(false);

  const [createSite, { loading, data, error }] = useCreateSite();

  if (data?.createSite) {
    return <Redirect to="/admin/sites" />;
  }

  const handleSubmit = (site: CreateSiteInputType) => {
    const variables: CreateSiteMutationVariables = {
      parentSiteId: siteId,
      site: {
        name: site.name,
        externalId: site.externalId || '',
      },
    };
    createSite({ variables });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpen(true);
    onCloseMenu(event);
  };

  const config = getStaticTemplate('create-site');

  return (
    <>
      <Button
        aria-label="quick add"
        onClick={handleClickOpen}
        classes={{
          root: classes.root,
        }}
      >
        Add child site
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Create child site</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the site name below and click create.
            {error && <ErrorDisplay error={error} />}
          </DialogContentText>
          <FormlyForm
            config={config}
            onSubmit={handleSubmit}
            onCancel={handleClose}
            submitting={loading}
            modal
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
