import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import React from 'react';
import { getFileUrl } from 'utils';

interface IProps {
  data: any;
  file: any;
}

export default function DownloadFile(props: IProps) {
  const { data, file } = props;

  const getDownloadFileUrl = (x: { id: string; name: string }) =>
    getFileUrl(data!.libraryFolder!.downloadFileUrlTemplate, x.id, x.name);

  const handleClick = () => {
    const fileUrl = getDownloadFileUrl(file);
    window.location.href = fileUrl;
  };

  return (
    <Tooltip title={`Download ${file.name}`}>
      <IconButton className="action-button" onClick={handleClick}>
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
}

